/* tslint:disable */
/* eslint-disable */
/**
 * Parcela Express API
 * Parcela Express API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CreateAttachmentDto,
    CreateAttachmentDtoFromJSON,
    CreateAttachmentDtoFromJSONTyped,
    CreateAttachmentDtoToJSON,
} from './';

/**
 * 
 * @export
 * @interface OpenDigitalAccountDto
 */
export interface OpenDigitalAccountDto {
    /**
     * 
     * @type {string}
     * @memberof OpenDigitalAccountDto
     */
    document: string;
    /**
     * 
     * @type {CreateAttachmentDto}
     * @memberof OpenDigitalAccountDto
     */
    attachment: CreateAttachmentDto;
}

export function OpenDigitalAccountDtoFromJSON(json: any): OpenDigitalAccountDto {
    return OpenDigitalAccountDtoFromJSONTyped(json, false);
}

export function OpenDigitalAccountDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): OpenDigitalAccountDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'document': json['document'],
        'attachment': CreateAttachmentDtoFromJSON(json['attachment']),
    };
}

export function OpenDigitalAccountDtoToJSON(value?: OpenDigitalAccountDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'document': value.document,
        'attachment': CreateAttachmentDtoToJSON(value.attachment),
    };
}


