import { Flex, GridItem, Image, SimpleGrid, useBreakpointValue, Spinner } from '@chakra-ui/react';
import React from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';

import { Formik } from 'formik';

import { CreateAttachmentDto, CreateAttachmentDtoExtensionEnum, DigitalAccountApi, OpenDigitalAccountDto } from '../../clients';
import Button from '../../components/Button';
import FormLabel from '../../components/Form/FormLabel';
import Text from '../../components/Text';
import Title from '../../components/Title';
import { urlImages } from '../../config/constants';
import { getApiAuthConfig } from '../../services/api.service';
import OpenAccountAttachment from './components/OpenAccountAttachment';
import ConfirmInformation from './components/ConfirmInformation';
import { maskCpfOrCnpj } from '../../services/masks.service';

export type Attachment = {
	name: string;
	extension: string;
	size: number;
	data: string;
};

const OpenDigitalAccount: React.FC = () => {
	const location = useLocation();
	const { addToast } = useToasts();
	const apiConfig = getApiAuthConfig();
	const history = useHistory();
	const digitalAccountApi = new DigitalAccountApi(apiConfig);
	const query = new URLSearchParams(location.search);
	const document = query.get('document');
	const [isLoading, setIsLoading] = React.useState<boolean>(false);
	const [verifyDigitalAccountLoading, setVerifyDigitalAccountLoading] = React.useState<boolean>(true);
	const [hasDigitalAccount, setHasDigitalAccount] = React.useState<boolean>(false);
	const [attachment, setAttachment] = React.useState<CreateAttachmentDto>({
		name: '',
		extension: CreateAttachmentDtoExtensionEnum.Doc,
		size: 0,
		data: '',
	});
	const isMobile = useBreakpointValue({ base: true, lg: false });

	const handleFormSubmit = async () => {
		if (!attachment.name || !document) {
			return;
		}

		const openDigitalAccountDto: OpenDigitalAccountDto = {
			document,
			attachment,
		};

		setIsLoading(true);

		try {
			await digitalAccountApi.openDigitalAccount({ openDigitalAccountDto });

			history.push(`digital-account/finished?sent_email=true`);
		} catch {
			addToast('Erro ao abrir conta digital', {
				appearance: 'error',
				autoDismiss: true,
			});
		} finally {
			setIsLoading(false);
		}
	};

	const initialValues = {
		legal_nature: '',
		patrimony_amount: 0,
		accept: false,
	};

	React.useEffect(() => {
		(async function () {
			if (!document) {
				return;
			}
			try {
				setVerifyDigitalAccountLoading(true);
				const response = await digitalAccountApi.getDigitalCredentials({
					document: document,
				});
				setHasDigitalAccount(response.has_digital_account);
			} catch {
				addToast('Erro ao verificar o documento.', {
					appearance: 'error',
					autoDismiss: true,
				});
			} finally {
				setVerifyDigitalAccountLoading(false);
			}
		})();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [document]);

	return (
		<Flex direction={['column', 'row']} height='100%' justifyContent='space-between'>
			<Flex maxH='100%' direction='column' alignItems='center' padding='30px' width='100%'>
				<Flex direction='column' width='100%' alignItems='center'>
					<Title color='blackAlpha.600' id='onr-pre-register-title-1' textAlign='center' fontSize='xl'>
						DADOS PARA ABERTURA DE CONTA DIGITAL
					</Title>
				</Flex>
				<Flex direction='column' width='100%' alignItems='center'>
					<Formik validateOnMount initialValues={initialValues} onSubmit={handleFormSubmit}>
						{({ handleSubmit, isValid, setFieldValue, values }) => (
							<form onSubmit={handleSubmit} style={{ width: '100%' }}>
								<SimpleGrid columns={12} color='gray.700' gap={2}>
									<GridItem colSpan={{ base: 12 }}>
										<Text id='open-digital-legal-information-title' textAlign='center' fontSize='lg' color='darkGrey' mt='2'>
											Informações Legais
										</Text>
									</GridItem>
									{verifyDigitalAccountLoading ? (
										<GridItem colSpan={{ base: 12 }}>
											<Flex direction='column' justify='center' align='center' mt='40px'>
												<Spinner size='lg' color='darkGrey' />
												<Text fontSize='lg' color='darkGrey' id='open-digital-loading-text' mt={4}>
													Verificando documento, aguarde.
												</Text>
											</Flex>
										</GridItem>
									) : (
										<>
											{hasDigitalAccount ? (
												<GridItem colSpan={{ base: 12 }}>
													<Text id='open-digital-document-already-exists-text' fontSize='md' color='darkGrey' my='4'>
														O documento <strong>{maskCpfOrCnpj(document)}</strong> já possui uma conta digital cadastrada em nosso sistema.
														<br />
														Entre em contato com o suporte para mais informações.
													</Text>
												</GridItem>
											) : (
												<>
													<GridItem colSpan={{ base: 12 }}>
														<Flex direction='column' my='5'>
															<Text id='open-digital-document-information-text' fontSize='md' color='darkGrey' my='4'>
																A abertura de conta BTG será realizada no documento <strong>{maskCpfOrCnpj(document)}.</strong>
															</Text>
															<FormLabel id='open-digital-account-legal-nature-label' color='darkGrey' fontSize='md' fontWeight='medium'>
																Anexar Termo de Posse
															</FormLabel>
															<OpenAccountAttachment attachment={attachment} setAttachment={setAttachment} />
														</Flex>
													</GridItem>
													<GridItem colSpan={{ base: 12 }}>
														<ConfirmInformation setFieldValue={setFieldValue} values={values} />
													</GridItem>
													<GridItem colSpan={{ base: 12 }}>
														<Button
															bgColor='#234e88'
															id='open-digital-account-submit-button'
															type='submit'
															disabled={!isValid || isLoading || !attachment.name || !values.accept}
															w='100%'
														>
															Enviar
														</Button>
													</GridItem>
												</>
											)}
										</>
									)}
								</SimpleGrid>
							</form>
						)}
					</Formik>
				</Flex>
			</Flex>
			{!isMobile && (
				<Flex width='100%' justifyContent='flex-end'>
					<Image maxH='100%' src={urlImages.openDigitalAccountOnrBtg} />
				</Flex>
			)}
		</Flex>
	);
};

export default OpenDigitalAccount;
