import { Box } from '@chakra-ui/react';
import React, { ReactNode } from 'react';

import Text from '../../../components/Text';

type FooterContactProps = {
	id: string;
	icon: ReactNode;
	text: string;
};

const FooterContact: React.FC<FooterContactProps> = (props: FooterContactProps) => {
	const { id, icon, text } = props;
	return (
		<Box
			position='relative'
			border='1px solid white'
			borderRadius='22px'
			p='16px'
			fontWeight='bold'
			textAlign='center'
			display='flex'
			flexDirection='column'
			alignItems='center'
		>
			{icon}
			<Text id={id} color='white' fontSize={['14px', '24px']} mt='8px'>
				{text}
			</Text>
		</Box>
	);
};

export default FooterContact;
