/* tslint:disable */
/* eslint-disable */
/**
 * Parcela Express API
 * Parcela Express API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Permission
 */
export interface Permission {
    /**
     * 
     * @type {Array<string>}
     * @memberof Permission
     */
    actions: Array<PermissionActionsEnum>;
    /**
     * 
     * @type {string}
     * @memberof Permission
     */
    subject: PermissionSubjectEnum;
}

/**
* @export
* @enum {string}
*/
export enum PermissionActionsEnum {
    Manage = 'manage',
    Create = 'create',
    Read = 'read',
    Update = 'update',
    Delete = 'delete'
}/**
* @export
* @enum {string}
*/
export enum PermissionSubjectEnum {
    PageNotFound = 'PageNotFound',
    BackofficePos = 'BackofficePos',
    BackofficeUsers = 'BackofficeUsers',
    Billet = 'Billet',
    Calendar = 'Calendar',
    CancelSale = 'CancelSale',
    ChangeSeller = 'ChangeSeller',
    CostPlanDetails = 'CostPlanDetails',
    CostPlans = 'CostPlans',
    CreateCostPlans = 'CreateCostPlans',
    CreateSalesPlan = 'CreateSalesPlan',
    CreateSeller = 'CreateSeller',
    Customization = 'Customization',
    Dashboard = 'Dashboard',
    Fees = 'Fees',
    Financial = 'Financial',
    Link = 'Link',
    MyAccount = 'MyAccount',
    MyData = 'MyData',
    MyPlan = 'MyPlan',
    Pos = 'POS',
    PaymentDetails = 'PaymentDetails',
    PaymentsHistory = 'PaymentsHistory',
    Protocols = 'Protocols',
    ProtocolsCustom = 'ProtocolsCustom',
    ProtocolsHistory = 'ProtocolsHistory',
    ProtocolsManagement = 'ProtocolsManagement',
    Reports = 'Reports',
    SaleDetails = 'SaleDetails',
    SalesHistory = 'SalesHistory',
    SalesHistoryPayment = 'SalesHistoryPayment',
    SalesPlan = 'SalesPlan',
    SalesPlanDetails = 'SalesPlanDetails',
    Sell = 'Sell',
    SellerCredentials = 'SellerCredentials',
    Sellers = 'Sellers',
    Services = 'Services',
    Support = 'Support',
    Users = 'Users',
    Roles = 'Roles',
    UploadRetFile = 'UploadRetFile',
    Merchants = 'Merchants',
    MerchantUsers = 'MerchantUsers',
    EmailsCustom = 'EmailsCustom',
    PaymentCheckoutTest = 'PaymentCheckoutTest',
    ReportCustomization = 'ReportCustomization',
    Responsibles = 'Responsibles',
    Goals = 'Goals',
    Pix = 'Pix',
    BillPaymentHistory = 'BillPaymentHistory',
    BillPayment = 'BillPayment',
    CheckoutsCustomization = 'CheckoutsCustomization',
    MonthlyTransactionsAlerts = 'MonthlyTransactionsAlerts',
    Alerts = 'Alerts',
    AssignmentTransactionAlerts = 'AssignmentTransactionAlerts',
    Notifications = 'Notifications',
    PaperRollOrder = 'PaperRollOrder',
    FindDda = 'FindDda',
    DigitalAccount = 'DigitalAccount',
    BankAccount = 'BankAccount',
    SellerChangesHistory = 'SellerChangesHistory',
    PosPassword = 'PosPassword',
    ReceiptPosCustom = 'ReceiptPosCustom',
    CreateNotifications = 'CreateNotifications',
    SearchNotifications = 'SearchNotifications',
    Contract = 'Contract'
}

export function PermissionFromJSON(json: any): Permission {
    return PermissionFromJSONTyped(json, false);
}

export function PermissionFromJSONTyped(json: any, ignoreDiscriminator: boolean): Permission {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'actions': json['actions'],
        'subject': json['subject'],
    };
}

export function PermissionToJSON(value?: Permission | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'actions': value.actions,
        'subject': value.subject,
    };
}


