import { Box, Container, Flex, Image, Stack, useBreakpointValue } from '@chakra-ui/react';
import React from 'react';
import { useLocation, useHistory } from 'react-router-dom';

import Text from '../../components/Text';
import { urlImages } from '../../config/constants';
import ColorBoxes from '../OnrLandingPage/components/ColorBoxes';
import { colors } from '../OnrLandingPage/utils/constants';
import Button from '../../components/Button';

const TransferInstructions: React.FC = () => {
	const maxWidth = useBreakpointValue({ base: '100%', xl: '90%', '2xl': '70vw' });
	const history = useHistory();
	const location = useLocation();
	const query = new URLSearchParams(location.search);
	const isPexCustomer = query.get('is_pex_customer') === 'true';

	const steps = [
		'1. Envie um e-mail para: assessoriapexbtg@parcelaexpress.com.br;',
		'2. A solicitação deve ser feita pelo mesmo e-mail cadastrado em sua conta BTG;',
		'3. No e-mail, inclua as seguintes informações: nome completo, CPF, número da conta e a solicitação de migração;',
		'4. Aguarde até 2 dias úteis para a conclusão da migração.',
	];

	return (
		<Flex maxH='100%' flexDirection='column' px={['20px', '0']}>
			<Container centerContent px={['0', '1rem']} maxW={maxWidth}>
				<Box w='full' pt='40px' textAlign='center'>
					<Stack flexDirection={['column', 'row']} justifyContent='space-between' alignItems='center' px='20px' mb='20px'>
						<Flex alignItems='end' mb={['15px', '0']}>
							<ColorBoxes fat />
						</Flex>
						<Flex alignItems='center' flexDirection={['column', 'row']}>
							<Image src={urlImages.openDigitalAccountOnrBtgParcelaExpress} mb={4} mr={['0', '30px']} maxH='70px' />
							<Image src={urlImages.openDigitalAccountOnrBtgOnrLogo} mb={4} maxW='200px' />
						</Flex>
					</Stack>
					<Flex w='100%' justifyContent='space-between' flexDirection={['column', 'row']}>
						<Flex flexDirection='column' w={['100%', '55%']}>
							<Text
								id='transfer-instructions-title-text'
								color={colors.primaryColor}
								textAlign='justify'
								fontSize={['28px', '50px']}
								fontWeight='bold'
							>
								MIGRAÇÃO DE CONTA BANCÁRIA BTG PACTUAL
							</Text>
							<Text
								id='transfer-instructions-description-text'
								color={colors.primaryColor}
								textAlign='justify'
								fontSize={['20px', '28px']}
								mt='20px'
							>
								Você já possui uma conta no BTG Pactual? Para aproveitar os benefícios da parceria com o ONR e as facilidades exclusivas da
								Parcela Express e do BTG, será necessário migrar sua conta para a assessoria da Parcela Express.
							</Text>
							<Text
								id='transfer-instructions-steps-title-text'
								color={colors.primaryColor}
								textAlign='justify'
								fontSize={['20px', '28px']}
								mt='20px'
								mb={['10px', '0']}
								fontWeight='bold'
							>
								Siga o passo a passo para realizar a migração:
							</Text>
						</Flex>
						<Flex w={['100%', '40%']} p='1px' border='5px solid orange' maxH='400px' borderRadius='12px' bgColor='#185AB0'>
							<Image src={urlImages.openDigitalAccountOnrBtgCard} />
						</Flex>
					</Flex>

					<Flex flexDirection='column' mt='25px'>
						{steps.map((item, index) => (
							<Text
								id={`transfer-instructions-step-${index}-text`}
								key={index}
								color={colors.primaryColor}
								textAlign='justify'
								fontSize={['18px', '24px']}
								mb={['10px', '0']}
							>
								{item}
							</Text>
						))}
						<Text
							id='transfer-instructions-contact-text'
							my='40px'
							mr='20px'
							color={colors.primaryColor}
							textAlign='justify'
							fontSize={['18px', '24px']}
						>
							Após a migração, você estará pronto para aproveitar todos os benefícios da parceria. Em caso de dúvidas, entre em contato com
							a Parcela Express através dos contatos comercialcartorio@parcelaexpress.com.br, (31) 2581-8788 ou (31) 99950-3615 (Disponível
							WhatsApp).
						</Text>
						<Flex mb='40px' justifyContent='center'>
							<Button bgColor='#505C7F' color='white' size='lg' id='test' onClick={()=> history.push(`finished?is_pex_customer=${isPexCustomer}`)}>Saiba mais</Button>
						</Flex>
					</Flex>
				</Box>
			</Container>
		</Flex>
	);
};

export default TransferInstructions;
