import { Flex } from '@chakra-ui/react';
import React from 'react';

import Text from '../../../components/Text';
import { colors } from '../utils/constants';

type FooterOfficeInfoProps = {
	id: string;
	title: string;
	address: string;
};

const FooterOfficeInfo: React.FC<FooterOfficeInfoProps> = (props: FooterOfficeInfoProps) => {
	const { title, address, id } = props;
	return (
		<Flex flexDirection='column' w='45%'>
			<Text id={`${id}-title`} fontWeight='bold' textAlign='start' fontSize='16px' color={colors.primaryColor}>
				{title}
			</Text>
			<Text id={`${id}-address`} textAlign='start' fontSize='16px' color={colors.primaryColor} pt='10px'>
				{address}
			</Text>
		</Flex>
	);
};

export default FooterOfficeInfo;
