import { Box, Container, Flex, Image, Stack, useBreakpointValue, useDisclosure } from '@chakra-ui/react';
import React from 'react';
import { useLocation } from 'react-router-dom';
import Text from '../../components/Text';
import { urlImages } from '../../config/constants';
import ColorBoxes from '../OnrLandingPage/components/ColorBoxes';
import { colors } from '../OnrLandingPage/utils/constants';
import OpenDigitalAccountModal from './components/OpenDigitalAccountModal';

const OpenDigitalAccountFinished: React.FC = () => {
	const maxWidth = useBreakpointValue({ base: '100%', xl: '90%', '2xl': '70vw' });
	const location = useLocation();
	const query = new URLSearchParams(location.search);
	const isPexCustomer = query.get('is_pex_customer') === 'true';
	const sentEmail = query.get('sent_email') === 'true';
	const { isOpen, onClose, onOpen } = useDisclosure();

	React.useEffect(() => {
		if (sentEmail) {
			onOpen();
		}
		// eslint-disable-next-line
	}, [sentEmail]);

	const steps = isPexCustomer
		? [
				'Aguardar a abertura da conta BTG ou migração da conta BTG já existente para a assessoria da PEX Parcela Express;',
				'Após a confirmação da abertura da conta pelo banco BTG, conceder permissão dentro da plataforma Cartexpress/ONR, exclusivamente pelo usuário administrador, para a geração de PIX e boleto diretamente da conta do cartório;',
				'Se a máquina de cartão da serventia já for um terminal Android, a atualização do terminal será realizada automaticamente e de forma remota em até dois dias úteis, e o cartório poderá acessar seu analista de conta da Parcela Express para qualquer dúvida. A máquina poderá ser utilizada normalmente durante esse período;',
				'Se a máquina do cartório não for Android, mas sim do modelo tradicional Verifone, a Parcela Express enviará, em até 7 dias, uma nova máquina Android já atualizada com a marca da ONR para substituição da anterior. A máquina antiga deverá ser devolvida à Parcela Express assim que o cartório receber o novo terminal. Da mesma forma, o cartório poderá acionar o seu analista de contas em caso de dúvidas;',
				'O cartório que receber o novo terminal (máquina de cartão) também receberá um tutorial de uso da nova máquina;',
				'A plataforma web Cartexpress/ONR, para acompanhamento das transações, continuará com o mesmo acesso e todo o histórico de transações, porém vinculada a um novo domínio: sipeonr.parcelaexpress.com.br;',
				'Durante este período de transição, a serventia poderá utilizar normalmente nossa plataforma e a máquina de cartão da Parcela Express;',
				'Nesta nova parceria, o cartório continuará sendo assistido pela Parcela Express e continuará usufruindo de todas as nossas soluções de pagamento.',
		  ]
		: [
				'Após o preenchimento do termo de adesão on-line e a abertura ou migração da conta BTG, o cartório deverá aguardar o prazo máximo de três dias úteis para concluir o processo de adesão na PEX Parcela Express;',
				'Finalizado o processo de adesão, o cartório receberá, em até 7 dias, a máquina de cartão exclusiva da parceria ONR/Parcela Express, com um tutorial de uso deste novo terminal;',
				'Após o recebimento da máquina de cartão, a equipe da Parcela Express fará contato com o cartório para orientações, treinamento e envio dos logins de acesso à plataforma web ONR, vinculada às transações;',
				'Neste momento de treinamento, o analista da Parcela Express, responsável pelo cartório, solicitará ao usuário administrador que conceda permissão dentro da plataforma CartExpress/ONR para a geração de PIX e boleto diretamente da conta criada no BTG;',
				'O cartório estará apto a utilizar e oferecer todas as nossas soluções de pagamento a seus clientes;',
				'Nesta nova parceria, o cartório será acompanhado e assistido pela Parcela Express.',
		  ];

	return (
		<Box
			w='100%'
			minH='100vh'
			backgroundImage={isPexCustomer ? 'none' : `url('${urlImages.openDigitalAccountBackgroundOnr}')`}
			backgroundSize='cover'
			backgroundPosition='center'
			backgroundRepeat='no-repeat'
		>
			<Container maxW={maxWidth} centerContent px={['0', '1rem']}>
				<Box w='full' pt='40px' textAlign='center'>
					<Stack
						flexDirection={['column', 'row']}
						justifyContent={isPexCustomer ? 'space-between' : 'end'}
						alignItems='center'
						px='20px'
						mb='20px'
					>
						{isPexCustomer && (
							<Flex alignItems='end' mb={['15px', '0']}>
								<ColorBoxes fat />
							</Flex>
						)}
						<Flex alignItems='center' flexDirection={['column', 'row']}>
							<Image
								src={
									isPexCustomer ? urlImages.openDigitalAccountOnrBtgParcelaExpress : urlImages.openDigitalAccountOnrBtgParcelaExpressWhite
								}
								mb={4}
								mr={['0', '30px']}
								maxH='70px'
							/>
							<Image
								src={urlImages.openDigitalAccountOnrBtgOnrLogo}
								mb={4}
								maxW='200px'
								filter={isPexCustomer ? 'none' : 'grayscale(100%) brightness(100)'}
							/>
						</Flex>
					</Stack>
					<Text
						w='90%'
						color={isPexCustomer ? colors.primaryColor : 'white'}
						textAlign='justify'
						pl='50px'
						fontSize={['28px', '42px']}
						fontWeight={isPexCustomer ? 'bold' : 'normal'}
						id='open-digital-account-finished-next-steps-text'
					>
						<Box as='span' bg={colors.gradientTextColor} color='white' px='8px' fontWeight='bold'>
							PRÓXIMOS PASSOS
						</Box>{' '}
						APÓS A ADESÃO DA PARCERIA COM O ONR PARA CARTÓRIOS {isPexCustomer ? 'CREDENCIADOS' : 'NÃO CREDENCIADOS'} À PARCELA EXPRESS
					</Text>

					<Stack borderLeft={`1px solid ${isPexCustomer ? 'orange' : 'white'}`} ml='50px' mt='30px'>
						{steps.map((item, index) => (
							<Text
								key={index}
								id={`open-digital-account-finished-step-${index}-text`}
								w='90%'
								mb='15px'
								color={isPexCustomer ? colors.primaryColor : 'white'}
								textAlign='justify'
								pl='20px'
								fontSize={['18px', '20px']}
							>
								<Box as='span' color='orange.500' fontWeight='bold' px='8px'>
									{index + 1})
								</Box>{' '}
								{item}
							</Text>
						))}
					</Stack>

					<Text
						w={['78%', '85%']}
						color={isPexCustomer ? colors.primaryColor : 'white'}
						textAlign='justify'
						ml='50px'
						my='30px'
						fontSize={['18px', '20px']}
						id='open-digital-account-finished-contact-text'
					>
						Em caso de dúvidas, entre em contato com a Parcela Express através dos contatos comercialcartorio@parcelaexpress.com.br, (31)
						2581-8788 ou (31) 99950-3615 (Disponível WhatsApp).
					</Text>
				</Box>
			</Container>
			<OpenDigitalAccountModal isOpen={isOpen} onClose={onClose} />
		</Box>
	);
};

export default OpenDigitalAccountFinished;
