import React from 'react';

import { Switch } from 'react-router-dom';

import 'fontsource-source-sans-pro';
import '@fontsource/open-sans';

import { ChakraProvider, extendTheme } from '@chakra-ui/react';
import { useToasts } from 'react-toast-notifications';
import Loader from '../../containers/Loader';

import FetchInterceptor from 'fetch-interceptor';
import Helmet from 'react-helmet';
import { usePubNub } from 'pubnub-react';

import Login from '../Login';
import ForgotPassword from '../ForgotPassword';
import Admin from '../Admin';

import PublicRoute from '../../components/PublicRoute';
import ProtectedRoute from '../../components/ProtectedRoute';
import CommonRoute from '../../components/CommonRoute';

import { AbilityContext } from '../../contexts/Can';
import { buildAbilityFor } from '../../config/ability';
import { useAuth } from '../../contexts/AuthProvider';
import { useTheme } from '../../contexts/ThemeProvider';
import { RealTimeChannel, RealTimeEvent } from '../../services/real-time.service';
import checkIfIsInMaintenance from '../../services/maintenance.service';

import PaymentLinkCheckout from '../PaymentLinkCheckout';
import ProtestLinkCheckout from '../ProtestLinkCheckout';
import Maintenance from '../Maintenance';
import PreRegister from '../PreRegister';
import OnrPreRegister from '../OnrPreRegister';
import OpenDigitalAccount from '../OpenDigitalAccount';
import TransferInstructions from '../TransferInstructions';
import OnrLandingPage from '../OnrLandingPage';
import OpenDigitalAccountFinished from '../OpenDigitalAccountFinished';

const defaultTheme = {
	radii: {
		md: '0.5rem',
		lg: '1.5rem',
	},
	colors: {
		primary: '#22365F',
		secondary: '#4c5bd3',
		lightPurple: '#293f6e',
		default: '#2c5282',
		defaultGrey: '#ccc',
		backgroundGray: '#E5E5E5',
		tableTitle: '#3d3d3d',
		error: 'tomato',
		succeeded: '#47ff62',
		pending: '#ffa547',
		textColor: '#22365F',
		menuTextColor: '#fff',
		darkGrey: '#4f4f4f',
	},
	fonts: {
		body:
			'Open Sans, Source Sans Pro, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
	},
	fontSizes: {
		'2xs': '0.55rem',
		xs: '0.65rem',
		sm: '0.75rem',
		'2sm': '0.810rem',
		md: '0.925rem',
		lg: '1.125rem',
		xl: '1.25rem',
		'2xl': '1.5rem',
		'3xl': '1.875rem',
		'4xl': '2.25rem',
		'5xl': '3rem',
		'6xl': '4rem',
	},
	components: {
		Input: {
			sizes: {
				sm: {
					field: {
						fontSize: 'sm',
						pl: 3,
						pr: 3,
						h: 6,
						borderRadius: 'sm',
					},
				},
			},
		},
		Select: {
			sizes: {
				sm: {
					field: {
						fontSize: 'sm',
						pl: 3,
						pr: 3,
						h: 6,
						borderRadius: 'sm',
					},
				},
			},
		},
	},
	sizes: {
		headerHeight: '6.5rem',
	},
};

function App(): React.ReactElement {
	const [appTheme, setAppTheme] = React.useState(extendTheme(defaultTheme));

	const { user, logout, signed } = useAuth();
	const { theme, updateTheme } = useTheme();
	const { addToast, removeAllToasts } = useToasts();
	const pubNub = usePubNub();

	const [isLoading, setLoading] = React.useState(false);
	const [isInMaintenanceMode, setMaintenanceMode] = React.useState(false);

	const subscribeToMaintenanceModeEvent = () => {
		pubNub.addListener({
			message: (event) => {
				const { message, channel } = event;

				if (channel === RealTimeChannel.MAINTENANCE_MODE) {
					const event = message[RealTimeEvent.MAINTENANCE_STATE];
					if (event) {
						const newMaintenanceState = event.all_services?.status === 'down';
						setMaintenanceMode(newMaintenanceState);
					}
				}
			},
		});

		pubNub.subscribe({ channels: [RealTimeChannel.MAINTENANCE_MODE] });
	}

	const checkMaintenanceMode = async () => {
		try {
			setLoading(true);
			const isInMaintenanceResponse = await checkIfIsInMaintenance();

			if (isInMaintenanceResponse) {
				setMaintenanceMode(true);
				setLoading(false);
				return;
			}
		} catch (error) {
			console.error('App - checkMaintenanceMode - Error: ', error);
		} finally {
			setLoading(false);
		}
	};

	React.useEffect(() => {
		subscribeToMaintenanceModeEvent();
		checkMaintenanceMode();

		const interceptor = FetchInterceptor.register({
			async onRequestFailure(response, request, controller) {
				if (response.status === 401) {
					removeAllToasts();

					addToast('Sessão expirada, realize o login novamente', {
						appearance: 'error',
						autoDismiss: true,
					});

					logout();
				}
			},
		});

		return () => {
			interceptor.unregister();
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	React.useEffect(() => {
		if (!signed) {
			setAppTheme(extendTheme(defaultTheme));
		}
	}, [signed]);

	React.useEffect(() => {
		if (user) {
			updateTheme(user.theme);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [user]);

	React.useEffect(() => {
		if (theme) {
			const updatedTheme = {
				...defaultTheme,
				colors: {
					...defaultTheme.colors,
					primary_checkbox: {
						500: theme.primary_color,
					},
					primary: theme.primary_color,
					secondary: theme.secondary_color,
					default: theme.default_color,
					textColor: theme.text_color,
					menuTextColor: theme.menu_text_color,
				},
			};

			setAppTheme(extendTheme(updatedTheme));
		}
	}, [theme]);

	const ability = buildAbilityFor(user);

	if (process.env.REACT_APP_STAGE !== 'production') {
		(window as any).ability = ability;
	}

	if (isInMaintenanceMode) {
		return (
			<ChakraProvider theme={appTheme}>
				<Maintenance />
			</ChakraProvider>
		);
	}

	return (
		<main className='App-main'>
			<Loader isOpen={isLoading} />

			<Helmet>
				<title>{`${process.env.REACT_APP_NAME}`}</title>
				<meta name='description' content={`${process.env.REACT_APP_NAME}`} />
				<link rel='icon' href={process.env.REACT_APP_FAVICON} />
			</Helmet>
			<ChakraProvider theme={appTheme}>
				<AbilityContext.Provider value={ability}>
					<Switch>
						<PublicRoute path='/' exact component={Login} />
						<CommonRoute path='/onr/:id' exact component={OnrLandingPage} />
						<CommonRoute path='/forgot-password' component={ForgotPassword} />
						<CommonRoute path='/payment-links/:token/checkout' component={PaymentLinkCheckout} />
						<CommonRoute path='/protest-links/:token/checkout' component={ProtestLinkCheckout} />
						<CommonRoute path='/pre-register' exact component={PreRegister} />
						<CommonRoute path='/pre-register/onr' component={OnrPreRegister} />
						<CommonRoute path='/open-digital-account' component={OpenDigitalAccount} />
						<CommonRoute path='/digital-account/transfer-instructions' component={TransferInstructions} />
						<CommonRoute path='/digital-account/finished' component={OpenDigitalAccountFinished} />
						<ProtectedRoute path='/admin' component={Admin} />
					</Switch>
				</AbilityContext.Provider>
			</ChakraProvider>
		</main>
	);
}

export default App;
