import { BreadcrumbItem, BreadcrumbLink, Flex, HStack } from '@chakra-ui/react';
import React from 'react';
import Paper from '../../containers/Paper';
import Breadcrumb from '../../components/Breadcrumb';

const Contract: React.FC = () => {
	return (
		<Flex flexDirection='column'>
			<HStack justify='space-between'>
				<Breadcrumb>
					<BreadcrumbItem>
						<BreadcrumbLink fontSize='md'>Resumo</BreadcrumbLink>
					</BreadcrumbItem>

					<BreadcrumbItem isCurrentPage>
						<BreadcrumbLink fontSize='md' fontWeight='semibold'>
							Contrato
						</BreadcrumbLink>
					</BreadcrumbItem>
				</Breadcrumb>
			</HStack>
			<Paper id='contract-paper-data' p={6}></Paper>
		</Flex>
	);
};

export default Contract;
