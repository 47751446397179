/* tslint:disable */
/* eslint-disable */
/**
 * Parcela Express API
 * Parcela Express API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface BankAccount
 */
export interface BankAccount {
    /**
     * 
     * @type {string}
     * @memberof BankAccount
     */
    bank_code: string;
    /**
     * 
     * @type {string}
     * @memberof BankAccount
     */
    agency_number: string;
    /**
     * 
     * @type {string}
     * @memberof BankAccount
     */
    agency_digit?: string;
    /**
     * 
     * @type {string}
     * @memberof BankAccount
     */
    account_number: string;
    /**
     * 
     * @type {string}
     * @memberof BankAccount
     */
    account_digit?: string;
    /**
     * 
     * @type {string}
     * @memberof BankAccount
     */
    type_account: BankAccountTypeAccountEnum;
    /**
     * 
     * @type {string}
     * @memberof BankAccount
     */
    pix_key?: string;
}

/**
* @export
* @enum {string}
*/
export enum BankAccountTypeAccountEnum {
    Current = 'current',
    Savings = 'savings'
}

export function BankAccountFromJSON(json: any): BankAccount {
    return BankAccountFromJSONTyped(json, false);
}

export function BankAccountFromJSONTyped(json: any, ignoreDiscriminator: boolean): BankAccount {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'bank_code': json['bank_code'],
        'agency_number': json['agency_number'],
        'agency_digit': !exists(json, 'agency_digit') ? undefined : json['agency_digit'],
        'account_number': json['account_number'],
        'account_digit': !exists(json, 'account_digit') ? undefined : json['account_digit'],
        'type_account': json['type_account'],
        'pix_key': !exists(json, 'pix_key') ? undefined : json['pix_key'],
    };
}

export function BankAccountToJSON(value?: BankAccount | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'bank_code': value.bank_code,
        'agency_number': value.agency_number,
        'agency_digit': value.agency_digit,
        'account_number': value.account_number,
        'account_digit': value.account_digit,
        'type_account': value.type_account,
        'pix_key': value.pix_key,
    };
}


