/* tslint:disable */
/* eslint-disable */
/**
 * Parcela Express API
 * Parcela Express API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GetDigitalCredentialsDto
 */
export interface GetDigitalCredentialsDto {
    /**
     * 
     * @type {string}
     * @memberof GetDigitalCredentialsDto
     */
    document: string;
    /**
     * 
     * @type {boolean}
     * @memberof GetDigitalCredentialsDto
     */
    has_credentials: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof GetDigitalCredentialsDto
     */
    scope?: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof GetDigitalCredentialsDto
     */
    has_digital_account: boolean;
}

export function GetDigitalCredentialsDtoFromJSON(json: any): GetDigitalCredentialsDto {
    return GetDigitalCredentialsDtoFromJSONTyped(json, false);
}

export function GetDigitalCredentialsDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetDigitalCredentialsDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'document': json['document'],
        'has_credentials': json['has_credentials'],
        'scope': !exists(json, 'scope') ? undefined : json['scope'],
        'has_digital_account': json['has_digital_account'],
    };
}

export function GetDigitalCredentialsDtoToJSON(value?: GetDigitalCredentialsDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'document': value.document,
        'has_credentials': value.has_credentials,
        'scope': value.scope,
        'has_digital_account': value.has_digital_account,
    };
}


