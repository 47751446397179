import { Box, Button as ChakraButton, Flex, Image, Stack, useDisclosure } from '@chakra-ui/react';
import React from 'react';
import { FaWhatsapp } from 'react-icons/fa';
import { useLocation, useHistory } from 'react-router-dom';

import Button from '../../../components/Button';
import Text from '../../../components/Text';
import { urlImages } from '../../../config/constants';
import { colors } from '../utils/constants';
import BenefitItems from './BenefitItems';
import ColorBoxes from './ColorBoxes';
import NoOpenAccountModal from './NoOpenAccountModal';
import { prefixUrl } from '../../../services/prefix-url.service';

const SecondLandingPage: React.FC = () => {
	const { isOpen, onClose, onOpen } = useDisclosure();
	const location = useLocation();
	const query = new URLSearchParams(location.search);
	const is_pex_customer = query.get('is_pex_customer') === 'true';
	const document = query.get('document');
	const history = useHistory();
	
	const navigateToParam = async (url: string, hasPrefix: boolean = false) => {
	  if (hasPrefix) {
		window.location.href = url;
	  } else {
		history.push(url);
	  }
	};

	const navigateToWhatsApp = () => {
		window.open(
			'https://wa.me/5531999503615?text=Olá,%20preciso%20de%20ajuda%20com%20a%20abertura%20de%20conta%20BTG%20Pactual.',
			'_blank'
		);
	};

	const benefitItems = [
		{
			icon: urlImages.openDigitalAccountOnrBtgIcon1,
			title: 'Conta sem mensalidade',
			description: 'Desfrute de uma conta empresarial sem custos mensais',
		},
		{
			icon: urlImages.openDigitalAccountOnrBtgIcon2,
			title: 'Máquina Android',
			description: 'Receba uma máquina Android em comodato, sem custos fixos mensais',
		},
		{
			icon: urlImages.openDigitalAccountOnrBtgIcon3,
			title: 'Geração de PIX QR Code e boleto bancário',
			description: 'Emita diretamente pela sua conta no BTG, tanto na máquina quanto no link de pagamento',
		},
		{
			icon: urlImages.openDigitalAccountOnrBtgIcon4,
			title: 'Boleto com beneficiário próprio',
			description: 'Personalize seus boletos com o nome do seu cartório',
		},
		{
			icon: urlImages.openDigitalAccountOnrBtgIcon5,
			title: 'PIX QR Code dinâmico com custo zero',
			description: 'Crie códigos dinâmicos sem pagar taxas adicionais',
		},
		{
			icon: urlImages.openDigitalAccountOnrBtgIcon6,
			title: 'Recebimento de PIX QR Code instantâneo',
			description: 'Receba pagamentos via PIX de forma imediata',
		},
		{
			icon: urlImages.openDigitalAccountOnrBtgIcon7,
			title: 'Conta sem mensalidade',
			description:
				'Monitore suas transações com níveis de acesso customizados, sem necessidade de compartilhar dados bancários com outros funcionários',
		},
		{
			icon: urlImages.openDigitalAccountOnrBtgIcon8,
			title: 'Abra sua conta PF ou PJ de forma rápida',
			description: 'Processo 100% online sem burocracia e sem complicação',
		},
	];

	return (
		<>
			<Flex flexDirection='column' pt={['20px', '160px']} pb={['30px', '60px']}>
				<Flex alignItems='end' w={['100%', '42%']} justifyContent={['center', 'right']}>
					<ColorBoxes />
				</Flex>
				<Flex flexDirection='row' w='100%' alignItems='start' justifyContent='right'>
					<Flex w='40%' display={['none', 'flex']}>
						<Image mt='130px' src={urlImages.openDigitalAccountOnrBtgCard} />
					</Flex>
					<Flex w={['100%', '60%']} flexDirection='column'>
						<Text
							left={['0', '-60px']}
							id='second-landing-page-title-text'
							color='white'
							textAlign={['center', 'start']}
							fontSize={['40px', '48px']}
							fontWeight='bold'
							position='relative'
						>
							{!is_pex_customer ? (
								<>
									Agora é hora de abrir <br />a sua conta BTG!
								</>
							) : (
								<>
									Você já é cliente <br />
									PEX - Parcela Express?
								</>
							)}
						</Text>
						<Text
							w={['100%', '65%']}
							ml='10px'
							pt='15px'
							id='second-landing-page-subtitle-text'
							color='white'
							textAlign={['center', 'start']}
							fontSize='24px'
							position='relative'
						>
							{!is_pex_customer ? (
								<>
									Aproveite todos os benefícios exclusivos da parceria e abra agora sua conta no <strong>BTG Pactual</strong>
								</>
							) : (
								<>
									Você vai aproveitar todos os <br /> benefícios da parceria entre <br /> a <strong>PEX - Parcela Express e ONR</strong>
								</>
							)}
						</Text>
					</Flex>
				</Flex>
				<Flex flexDirection='column' w='100%' justifyContent='center' alignItems='center' mt={['40px', '80px']}>
					{benefitItems.map((item, index) => (
						<BenefitItems
							key={index}
							icon={item.icon}
							title={item.title}
							description={item.description}
							titleId={`${item.title}-index`}
							descriptionId={`${item.description}-index`}
						/>
					))}
				</Flex>
				<Box mx='45px' mt={['0', '110px']} position='relative' h={['auto', '500px']}>
					<Box
						position={['relative', 'absolute']}
						top='0'
						left='0'
						w='100%'
						h='100%'
						backgroundImage="url('https://parcela-sub-api-prod-uploads.s3.us-east-1.amazonaws.com/btgBackground.png')"
						backgroundSize='cover'
						backgroundPosition='center'
						backgroundRepeat='no-repeat'
						opacity='0.5'
					/>
					<Stack
						position='relative'
						px={['0', '45px']}
						justifyContent={['center', 'flex-end']}
						alignItems={['center', 'flex-start']}
						h='100%'
						color='white'
						pb='60px'
						spacing='10px'
						flexDirection='column'
					>
						<Flex alignItems={['end']} justifyContent={'center'} pt={['0', '40px']} pb={['0', '15px']}>
							<ColorBoxes fat />
						</Flex>
						<Text textAlign={['center', 'start']} id='second-landing-page-background-title-text' fontSize='36px' color='white' px='8px'>
							Experimente a modernidade <br /> e eficiência que seu cartório <br /> merece com a parceria
						</Text>{' '}
						<Text id='second-landing-page-background-subtitle-text' fontSize='36px' bg={colors.gradientTextColor} color='white' px='8px'>
							PEX - Parcela Express e BTG Pactual! <br />
						</Text>{' '}
					</Stack>
				</Box>
				<Stack
					w='100%'
					mt={['0', '90px']}
					alignItems={['center', 'end']}
					justifyContent='space-between'
					flexDirection={['column', 'row']}
					px={['0', '15px']}
				>
					<Button
						bg={colors.gradientButtonColor}
						w={['350px', '420px']}
						h='50px'
						id='second-landing-page-open-new-account-button'
						mr={['0', '50px']}
						onClick={() => {
							navigateToParam(!is_pex_customer ? `/open-digital-account?document=${document}` : prefixUrl('sipeonr', '?is_digital_account=true'), true);
						}}
					>
						Abra sua conta e aproveite os benefícios
					</Button>
					<Button
						bg={colors.gradientButtonColor}
						w={['350px', '420px']}
						h='50px'
						id='second-landing-page-already-have-account-button'
						onClick={() => {
							navigateToParam(`/digital-account/transfer-instructions?is_pex_customer=${is_pex_customer ? 'true' : 'false'}`);
						}}
					>
						Já tenho conta BTG e quero aderir a parceria
					</Button>
				</Stack>
				<Stack
					w='100%'
					mt={['10px', '90px']}
					px='80px'
					alignItems={['center', 'end']}
					justifyContent='space-between'
					flexDirection={['column', 'row']}
				>
					<ChakraButton
						_hover={{ bgColor: 'red.500', opacity: 0.8 }}
						bgColor='red.500'
						px='16px'
						py='8px'
						display='flex'
						w='300px'
						onClick={onOpen}
					>
						<Text
							id='second-landing-page-no-open-account-button'
							color='white'
							fontSize='16px'
							fontWeight='bold'
							display='flex'
							textAlign='center'
						>
							Não desejo abrir uma. <br /> conta BTG agora
						</Text>
					</ChakraButton>
					<ChakraButton
						onClick={() => navigateToWhatsApp()}
						_hover={{ bg: 'green', opacity: 0.8 }}
						bg='green'
						px='16px'
						py='8px'
						display='flex'
						w='300px'
					>
						<FaWhatsapp color='white' size={20} />
						<Text
							id='second-landing-page-contact-button'
							ml='15px'
							color='white'
							fontSize='16px'
							fontWeight='bold'
							display='flex'
							textAlign='center'
						>
							Estou com dúvidas. <br /> Entrar em contato com a PEX!
						</Text>
					</ChakraButton>
				</Stack>
				<NoOpenAccountModal isOpen={isOpen} onClose={onClose} isPexCustomer={!is_pex_customer} document={document}/>
			</Flex>
		</>
	);
};

export default SecondLandingPage;
