import React from 'react';
import Checkbox from '../../../components/Checkbox';

type LandingPageCheckboxProps = {
	id: string;
	label: string;
	isChecked: boolean;
	onChange: () => void;
};

const LandingPageCheckbox: React.FC<LandingPageCheckboxProps> = (props: LandingPageCheckboxProps) => {
	const { id, label, isChecked, onChange } = props;
	return (
		<Checkbox
			id={id}
			isChecked={isChecked}
			onChange={onChange}
			textAlign='justify'
			sx={{
				'.chakra-checkbox__control': {
					width: '30px',
					height: '30px',
					bg: 'white',
					border: '2px solid #EF7E07',
					borderRadius: 'md',
				},
				'.chakra-checkbox__control[data-checked]': {
					bg: '#EF7E07',
					borderColor: 'white',
				},
				'.chakra-checkbox__control[data-checked]:hover': {
					bg: '#EF7E07',
				},
				'.chakra-checkbox__label': {
					fontSize: ['18px', '24px'],
					lineHeight: '1.25',
					marginLeft: '12px',
				},
				mb: ['24px', '16px'],
			}}
		>
			{label}
		</Checkbox>
	);
};

export default LandingPageCheckbox