import { Circle, Flex, Image, Stack } from '@chakra-ui/react';
import React from 'react';

import Text from '../../../components/Text';
import { colors } from '../utils/constants';

type BenefitItemsProps = {
	icon: string;
	title: string;
	description: string;
	titleId: string;
	descriptionId: string;
};
const BenefitItems: React.FC<BenefitItemsProps> = (props: BenefitItemsProps) => {
	const { icon, title, description, titleId, descriptionId } = props;
	return (
		<Flex w={['100%', '75%']} flexDirection='column' mb='25px' px={['20px', '0']}>
			<Stack flexDirection='row'>
				<Circle size={['40px', '48px']} bg={colors.gradientTextColor} mr={['10px', '30px']} display='flex'>
					<Image src={icon} />
				</Circle>
				<Text id={titleId} color='white' fontSize={['16px', '24px']} fontWeight='bold' textAlign='start'>
					{title}
				</Text>
			</Stack>
			<Text id={descriptionId} pl={['50px', '80px']} color='white' fontSize={['14px', '24px']} mt={[0, 2]} textAlign={['justify', 'start']}>
				{description}
			</Text>
		</Flex>
	);
};
export default BenefitItems;
