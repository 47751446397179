import { Flex, FormControl, FormLabel, FormLabelProps, Input, InputProps } from '@chakra-ui/react';
import Text from 'components/Text';
import React from 'react';
import { AiOutlineDownload } from 'react-icons/ai';

type FileUploadInput = {
    label?: string;
    containerProps?: FormLabelProps;
    inputProps?: InputProps;
		id: string
}

const FileUploadInput: React.FC<FileUploadInput> = ({ label, containerProps, inputProps }) => {
    return (
        <FormControl maxW='180'>
            <FormLabel id='file-upload-form-label' _hover={{ opacity: 0.8 }} cursor='pointer' htmlFor='file' borderColor='black' borderWidth={1} padding={2} borderRadius={4} {...containerProps}>
                <Flex alignItems='center' justifyContent='space-around'>
                    <AiOutlineDownload />
                    <Text id='send-file-text' color='blackAlpha.600'> {label || 'Enviar Arquivo'}</Text>
                </Flex>
            </FormLabel>
            <Input id='file' display='none' type='file' {...inputProps} />
        </FormControl>
    );
}

export default FileUploadInput;