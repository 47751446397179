/* tslint:disable */
/* eslint-disable */
/**
 * Parcela Express API
 * Parcela Express API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface OpenDigitalAccountResponseDto
 */
export interface OpenDigitalAccountResponseDto {
    /**
     * 
     * @type {string}
     * @memberof OpenDigitalAccountResponseDto
     */
    redirect_url: string;
}

export function OpenDigitalAccountResponseDtoFromJSON(json: any): OpenDigitalAccountResponseDto {
    return OpenDigitalAccountResponseDtoFromJSONTyped(json, false);
}

export function OpenDigitalAccountResponseDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): OpenDigitalAccountResponseDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'redirect_url': json['redirect_url'],
    };
}

export function OpenDigitalAccountResponseDtoToJSON(value?: OpenDigitalAccountResponseDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'redirect_url': value.redirect_url,
    };
}


