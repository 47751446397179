import React from 'react';

import { FormLabel as DefaultFormLabel, FormLabelProps } from '@chakra-ui/react';
import { useTheme } from 'contexts/ThemeProvider';

type DefaultFormLabelProps = FormLabelProps & {
	id: string;
};

const FormLabel: React.FC<DefaultFormLabelProps> = (props: DefaultFormLabelProps) => {
	const { theme } = useTheme();
	return (
		<DefaultFormLabel {...props} color={theme?.text_color || `darkGrey`} fontSize={props.fontSize || `md`} >
			{props.children}
		</DefaultFormLabel>
	);
};

export default FormLabel;
