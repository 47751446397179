import { Flex, Link } from '@chakra-ui/react';
import React from 'react';

import Checkbox from '../../../components/Checkbox';
import Text from '../../../components/Text';

type ConfirmationInformationTypes = {
	setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
	values: any;
};

const ConfirmInformation: React.FC<ConfirmationInformationTypes> = ({ setFieldValue, values }) => {
	const navigateToTerms = () => {
		window.open('/digital-account/finished', '_blank');
	};

	return (
		<Flex justifyContent='center' my='5' flexDirection='column' alignItems='center'>
			<Text id='test'>Após clicar em avançar seu documento será enviado ao banco.</Text>
			<Flex>
				<Checkbox
					checked={values.accept}
					id='onr-open-digital-account-confirmation-button'
					name='confirm_information'
					mr='2'
					onClick={(e: any) => {
						setFieldValue('accept', e.target.checked);
					}}
				/>

				<Text id='onr-open-digital-account-terms-text'>
					<Link
						color='#337ab7'
						onClick={() => {
							navigateToTerms();
						}}
					>
						Estou ciente dos próximos passos
					</Link>
				</Text>
			</Flex>
		</Flex>
	);
};

export default ConfirmInformation;
