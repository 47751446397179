import { Circle, Flex, Stack } from '@chakra-ui/react';
import React from 'react';
import { BiCheck } from 'react-icons/bi';

import Text from '../../../components/Text';
import { colors } from '../utils/constants';

type ModalBenefitItemsProps = {
	id: string;
	text: string;
};
const ModalBenefitItems: React.FC<ModalBenefitItemsProps> = (props: ModalBenefitItemsProps) => {
	const { text, id } = props;
	return (
		<Flex flexDirection='column' mb='10px'>
			<Stack flexDirection='row'>
				<Circle size='20px' bg={colors.gradientTextColor} mt='0.5rem' mr='5px' display='flex'>
					<BiCheck color='white' size='14px' />
				</Circle>
				<Text id={id} color='white' fontSize='14px'>
					{text}
				</Text>
			</Stack>
		</Flex>
	);
};
export default ModalBenefitItems;
