import { Flex, GridItem, SimpleGrid } from '@chakra-ui/react';
import React from 'react';

import FormControl from '../../../components/Form/FormControl';
import FormErrorMessage from '../../../components/Form/FormErrorMessage';
import FormLabel from '../../../components/Form/FormLabel';
import FormSelect from '../../../components/Form/FormSelect';
import Title from '../../../components/Title';

type CustomizationFormTypes = {
	values: {
		active_debit: string;
		active_credit_1x: string;
		active_credit_2x_to_18x: string;
		active_billet: string;
		active_pix_pos: string;
		active_pix_online: string;
	};
};

const CustomizationForm: React.FC<CustomizationFormTypes> = ({ values }) => {
	return (
		<Flex direction='column' my='20px'>
			<Title id='onr-pre-register-customization-form-title' textAlign='center' textDecoration='underline' mb='30px' color='blackAlpha.600'>
				Customização do Cadastro
			</Title>
			<SimpleGrid columns={{ base: 1, md: 6, xl: 12 }} gap={1}>
				<GridItem colSpan={{ base: 12, xl: 4 }}>
					<FormControl id='onr-pre-register-customization-form-debit-control' isRequired>
						<FormLabel
							color='blackAlpha.600'
							id='onr-pre-register-customization-form-debit-label'
							fontSize='md'
							fontWeight='bold'
							minH={12}
						>
							Utilizará Débito?
						</FormLabel>
						<FormSelect id='onr-pre-register-customization-form-debit-select' name='active_debit'>
							<option id='onr-pre-register-customization-form-debit-select-empty' value='' />
							<option id='onr-pre-register-customization-form-debit-select-true' value='true'>
								Sim
							</option>
							<option id='onr-pre-register-customization-form-debit-select-false' value='false'>
								Não
							</option>
						</FormSelect>
						<FormErrorMessage id='onr-pre-register-customization-form-debit-error-text' name='active_debit' />
					</FormControl>
				</GridItem>
				<GridItem colSpan={{ base: 12, xl: 4 }}>
					<FormControl id='onr-pre-register-customization-form-debit-pass-on-cost-control' isRequired={values.active_debit === 'true'}>
						<FormLabel color='blackAlpha.600' id='onr-pre-register-customization-form-debit-pass-on-cost-label' fontSize='md' fontWeight='bold' minH={12}>
							Repassar custo do débito para o cliente?
						</FormLabel>
						<FormSelect id='onr-pre-register-customization-form-pass-on-cost-debit-select' name='pass_on_cost_debit'>
							<option id='onr-pre-register-customization-form-pass-on-cost-debit-select-empty' value='' />
							<option id='onr-pre-register-customization-form-pass-on-cost-debit-select-true' value='true'>
								Sim
							</option>
							<option id='onr-pre-register-customization-form-pass-on-cost-debit-select-false' value='false'>
								Não
							</option>
						</FormSelect>
						<FormErrorMessage id='onr-pre-register-customization-form-debit-pass-on-cost-error-text' name='pass_on_cost_debit' />
					</FormControl>
				</GridItem>
				<GridItem colSpan={{ base: 12, xl: 4 }}>
					<FormControl id='onr-pre-register-customization-form-credit-1x-control' isRequired>
						<FormLabel color='blackAlpha.600' id='onr-pre-register-customization-form-credit-1x-label' fontSize='md' fontWeight='bold' minH={12}>
							Utilizará Crédito 1x?
						</FormLabel>
						<FormSelect id='onr-pre-register-customization-form-credit-1x-select' name='active_credit_1x'>
							<option id='onr-pre-register-customization-form-credit-1x-select-empty' value='' />
							<option id='onr-pre-register-customization-form-credit-1x-select-true' value='true'>
								Sim
							</option>
							<option id='onr-pre-register-customization-form-credit-1x-select-false' value='false'>
								Não
							</option>
						</FormSelect>
						<FormErrorMessage id='onr-pre-register-customization-form-credit-1x-error-text' name='active_credit_1x' />
					</FormControl>
				</GridItem>
				<GridItem colSpan={{ base: 12, xl: 4 }}>
					<FormControl
						id='onr-pre-register-customization-form-credit-1x-pass-on-cost-control'
						isRequired={values.active_credit_1x === 'true'}
					>
						<FormLabel
							color='blackAlpha.600'
							id='onr-pre-register-customization-form-credit-1x-pass-on-cost-label'
							fontSize='md'
							fontWeight='bold'
							minH={12}
						>
							Repassar custo do crédito 1x para o cliente?
						</FormLabel>
						<FormSelect id='onr-pre-register-customization-form-pass-on-cost-credit-1x-pass-on-cost-select' name='pass_on_cost_credit_1x'>
							<option id='onr-pre-register-customization-form-pass-on-cost-credit-1x-select-empty' value='' />
							<option id='onr-pre-register-customization-form-pass-on-cost-credit-1x-select-true' value='true'>
								Sim
							</option>
							<option id='onr-pre-register-customization-form-pass-on-cost-credit-1x-select-false' value='false'>
								Não
							</option>
						</FormSelect>
						<FormErrorMessage id='onr-pre-register-customization-form-credit-1x-pass-on-cost-error-text' name='pass_on_cost_credit_1x' />
					</FormControl>
				</GridItem>
				<GridItem colSpan={{ base: 12, xl: 4 }}>
					<FormControl id='onr-pre-register-customization-form-credit-2x-to-18x-control' isRequired>
						<FormLabel color='blackAlpha.600' id='onr-pre-register-customization-form-credit-2x-to-18x-label' fontSize='md' fontWeight='bold' minH={12}>
							Utilizará Crédito 2x a 18x?
						</FormLabel>
						<FormSelect id='onr-pre-register-customization-form-credit-2x-to-18x-select' name='active_credit_2x_to_18x'>
							<option id='onr-pre-register-customization-form-credit-2x-to-18x-select-empty' value='' />
							<option id='onr-pre-register-customization-form-credit-2x-to-18x-select-true' value='true'>
								Sim
							</option>
							<option id='onr-pre-register-customization-form-credit-2x-to-18x-select-false' value='false'>
								Não
							</option>
						</FormSelect>
						<FormErrorMessage id='onr-pre-register-customization-form-credit-2x-to-18x-error-text' name='active_credit_2x_to_18x' />
					</FormControl>
				</GridItem>
				<GridItem colSpan={{ base: 12, xl: 4 }}>
					<FormControl
						id='onr-pre-register-customization-form-credit-2x-to-18x-pass-on-cost-control'
						isRequired={values.active_credit_2x_to_18x === 'true'}
					>
						<FormLabel
							color='blackAlpha.600'
							id='onr-pre-register-customization-form-credit-2x-to-18x-pass-on-cost-label'
							fontSize='md'
							fontWeight='bold'
							minH={12}
						>
							Repassar custo do crédito 2x a 18x para o cliente?
						</FormLabel>
						<FormSelect
							id='onr-pre-register-customization-form-pass-on-cost-credit-2x-to-18x-pass-on-cost-select'
							name='pass_on_cost_credit_2x_to_18x'
						>
							<option id='onr-pre-register-customization-form-pass-on-cost-credit-2x-to-18x-select-empty' value='' />
							<option id='onr-pre-register-customization-form-pass-on-cost-credit-2x-to-18x-select-true' value='true'>
								Sim
							</option>
							<option id='onr-pre-register-customization-form-pass-on-cost-credit-2x-to-18x-select-false' value='false'>
								Não
							</option>
						</FormSelect>
						<FormErrorMessage
							id='onr-pre-register-customization-form-credit-2x-to-18x-pass-on-cost-error-text'
							name='pass_on_cost_credit_2x_to_18x'
						/>
					</FormControl>
				</GridItem>
				<GridItem colSpan={{ base: 12, xl: 4 }}>
					<FormControl id='onr-pre-register-customization-form-billet-control' isRequired>
						<FormLabel
							color='blackAlpha.600'
							id='onr-pre-register-customization-form-billet-label'
							fontSize='md'
							fontWeight='bold'
							minH={12}
						>
							Utilizará Boleto?
						</FormLabel>
						<FormSelect id='onr-pre-register-customization-form-billet-select' name='active_billet'>
							<option id='onr-pre-register-customization-form-billet-select-empty' value='' />
							<option id='onr-pre-register-customization-form-billet-select-true' value='true'>
								Sim
							</option>
							<option id='onr-pre-register-customization-form-billet-select-false' value='false'>
								Não
							</option>
						</FormSelect>
						<FormErrorMessage id='onr-pre-register-customization-form-billet-error-text' name='active_billet' />
					</FormControl>
				</GridItem>
				<GridItem colSpan={{ base: 12, xl: 4 }}>
					<FormControl id='onr-pre-register-customization-form-pix-pos-control' isRequired>
						<FormLabel
							color='blackAlpha.600'
							id='onr-pre-register-customization-form-pix-pos-label'
							fontSize='md'
							fontWeight='bold'
							minH={12}
						>
							Utilizará Pix na máquina?
						</FormLabel>
						<FormSelect id='onr-pre-register-customization-form-pix-pos-select' name='active_pix_pos'>
							<option id='onr-pre-register-customization-form-pix-pos-select-empty' value='' />
							<option id='onr-pre-register-customization-form-pix-pos-select-true' value='true'>
								Sim
							</option>
							<option id='onr-pre-register-customization-form-pix-pos-select-false' value='false'>
								Não
							</option>
						</FormSelect>
						<FormErrorMessage id='onr-pre-register-customization-form-pix-pos-error-text' name='active_pix_pos' />
					</FormControl>
				</GridItem>
			</SimpleGrid>
		</Flex>
	);
};

export default CustomizationForm;
