import { Box } from '@chakra-ui/react';
import React from 'react';

type ColorBoxesProps = {
	fat?: boolean;
};

const ColorBoxes: React.FC<ColorBoxesProps> = (props: ColorBoxesProps) => {
	const { fat } = props;
	return (
		<>
			<Box w={fat ? '20px' : '15px'} h={fat ? '30px' : '25px'} borderRadius='8px' bg='green.500' mr='8px' />
			<Box w={fat ? '20px' : '15px'} h={fat ? '40px' : '35px'} borderRadius='8px' bg='blue.500' mr='8px' />
			<Box w={fat ? '20px' : '15px'} h={fat ? '25px' : '20px'} borderRadius='8px' bg='orange.500' mr='8px' />
			<Box w={fat ? '20px' : '15px'} h={fat ? '20px' : '15px'} borderRadius='8px' bg='yellow.500' />
		</>
	);
};

export default ColorBoxes;
