import { Box, Flex, Image, Stack } from '@chakra-ui/react';
import React from 'react';
import { useHistory } from 'react-router-dom';

import Button from '../../../components/Button';
import Text from '../../../components/Text';
import { urlImages } from '../../../config/constants';
import { colors } from '../utils/constants';
import BenefitCards from './BenefitCards';

const FirstLandingPage: React.FC = () => {
	const history = useHistory();
	const navigateToParam = async (url: string) => {
		history.push(url);
	};
	return (
		<>
			<Stack
				w='full'
				justifyContent='space-between'
				flexDirection={['column', 'row']}
				alignItems='center'
				pl={['0', '50px']}
				pr={['0', '70px']}
			>
				<Image src={urlImages.openDigitalAccountOnrBtgOnrLogo} mb={4} maxW='200px' />
				<Image src={urlImages.openDigitalAccountOnrBtgParcelaExpress} mb={4} maxH='50px' />
			</Stack>
			<Stack w='100%' justifyContent='space-between' flexDirection='row' pl='0px' mt='10px'>
				<Flex flexDirection='column' w={['100%', '60%']}>
					<Flex flexDirection='column' mx='20px'>
						<Text
							id='first-landing-page-title-text'
							color={colors.primaryColor}
							textAlign={['justify', 'left']}
							fontSize='24px'
							lineHeight='1.25'
							position='relative'
						>
							O <strong>ONR - Operador Nacional do Registro de Imóveis Eletrônico</strong>, em parceria com a{' '}
							<strong>PEX - Parcela Express</strong>, a maior empresa de solução de pagamentos para serventias no Brasil, se unem para
							transformar a gestão financeira dos cartórios
						</Text>
						<Text
							mt='55px'
							mr={['0', '25px']}
							id='first-landing-page-subtitle-text'
							color={colors.primaryColor}
							textAlign={['justify', 'left']}
							fontSize='16px'
							lineHeight='1.5'
							position='relative'
							pl='25px'
							_before={{
								content: '""',
								position: 'absolute',
								top: '0',
								left: '0',
								width: '4px',
								height: '100%',
								background: colors.gradientTextColor,
							}}
						>
							Uma nova parceria que oferece <strong>facilidade no processo de pagamentos, segurança, automação</strong> e{' '}
							<strong>conformidade com as exigências legais</strong> do provimento nº 127, que disciplina a Plataforma do Sistema Integrado
							de Pagamentos no Eletrônicos (SIPE).
						</Text>
					</Flex>
				</Flex>
				<Flex display={['none', 'flex']} w='50%' justifyContent='end'>
					<Image src={urlImages.openDigitalAccountOnrBtgTerminalLogo} minW='400px' />
				</Flex>
			</Stack>
			<Stack w='100%' mt={['40px', '10px']} backgroundColor='#102344' alignItems='center'>
				<Text
					w={['100%', '75%']}
					mt={['40px', '100px']}
					mb='75px'
					color='white'
					fontSize={['36px', '48px']}
					id='first-landing-page-benefits-title-text'
				>
					O QUE{' '}
					<Box as='span' bg={colors.gradientButtonColor} color='white' px='8px'>
						SEU CARTÓRIO GANHA
					</Box>{' '}
					COM ESSA PARCERIA?
				</Text>
				<Flex mb={['0', '60px']} px='20px' flexDirection={['column', 'row']}>
					<BenefitCards id='1' text='Máquina android ONR com simulação de vendas e recibos personalizáveis' />
					<BenefitCards id='2' center text='Plataforma completa para geração de vendas e gestão dos recebíveis' />
					<BenefitCards id='3' text='Relatório gerenciais facilitando a conciliação financeira da serventia' />
				</Flex>
				<Flex mb={['0', '60px']} px='20px' flexDirection={['column', 'row']}>
					<BenefitCards id='4' text='Abertura de conta no banco BTG sem mensalidade' />
					<BenefitCards id='5' center text='Geração de PIX QR Code e boleto bancário diretamente pela conta BTG' />
					<BenefitCards id='6' text='PIX QR Code dinâmico com custo zero' />
				</Flex>
				<Flex mb={['0', '60px']} px='20px' flexDirection={['column', 'row']}>
					<BenefitCards id='7' text='Recebimento de PIX QR Code dinâmico na conta BTG de forma instantânea' />
					<BenefitCards id='8' center text='Suporte e atendimento especializado com o cuidado da PEX - Parcela Express' />
					<BenefitCards id='9' text='Repasse integral dos valores em D+1 para boleto e cartão, mesmo em parcelamentos em até 18x' />
				</Flex>
				<Flex mb={['0', '60px']} px='20px' flexDirection={['column', 'row']}>
					<BenefitCards id='10' text='Flexibilidade para o cartório escolher o repasse das taxas por modalidade de pagamento' />
					<BenefitCards id='11' center text='Atualização instantânea de status de pagamentos na plataforma' />
					<BenefitCards id='12' text='APIs de pagamento para integração com ERP ou site da serventia' />
				</Flex>
				<Flex mb={['0', '60px']} px='20px' justifyContent='space-between' flexDirection={['column', 'row']}>
					<BenefitCards
						width={['100%', '49%']}
						id='13'
						text='Processo automatizado de geração de vendas e baixas nos 25 sistemas de gestão já integrados com as APIS da PEX - Parcela
							Express'
					/>
					<BenefitCards
						width={['100%', '49%']}
						id='14'
						text='Acompanhamento das entradas de PIX via QR Code dinâmico, com níveis de acesso personalizados sem a necessidade de compartilhar
							dados bancários entre os funcionários'
					/>
				</Flex>
				<Flex mb={['0', '60px']} px='20px' justifyContent='space-between' flexDirection={['column', 'row']}>
					<BenefitCards width={['100%', '49%']} id='15' text='Parcelamento de títulos com código de barras' />
					<BenefitCards width={['100%', '49%']} id='16' text='Taxas únicas para todas as bandeiras' />
				</Flex>
			</Stack>
			<Stack w='100%' mt={['40px', '80px']} alignItems='center'>
				<Text id='first-landing-page-accept-open-account-text' fontSize='20px' color='#F66324' fontWeight='bold'>
					QUERO ADERIR A PARCERIA ONR E PEX - PARCELA EXPRESS
				</Text>
			</Stack>
			<Stack
				w='100%'
				mt={['20px', '90px']}
				alignItems={['center', 'end']}
				justifyContent='space-between'
				flexDirection={['column', 'row']}
				px='15px'
			>
				<Button
					bg={colors.gradientButtonColor}
					w={['350px', '420px']}
					h='50px'
					id='first-landing-page-already-have-account-button'
					mr={['0', '50px']}
					onClick={() => {
						navigateToParam('/onr/2?is_pex_customer=true');
					}}
				>
					Já sou cliente PEX - Parcela Express
				</Button>
				<Button
					bg={colors.gradientButtonColor}
					w={['350px', '420px']}
					h='50px'
					id='first-landing-page-no-have-account-button'
					onClick={() => {
						navigateToParam('/pre-register/onr');
					}}
				>
					Ainda não sou cliente PEX - Parcela Express
				</Button>
			</Stack>
		</>
	);
};

export default FirstLandingPage;
