import { Flex, GridItem, SimpleGrid, Input } from '@chakra-ui/react';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';

import { Formik } from 'formik';

import { PreRegisterSellerAddBankInfoDto, SellersApi } from '../../clients';
import Button from '../../components/Button';
import FormControl from '../../components/Form/FormControl';
import FormErrorMessage from '../../components/Form/FormErrorMessage';
import FormInput from '../../components/Form/FormInput';
import FormLabel from '../../components/Form/FormLabel';
import FormSelect from '../../components/Form/FormSelect';
import Title from '../../components/Title';
import { type_account } from '../../config/constants';
import { useCurrentSeller } from '../../contexts/SellerProvider';
import { getApiAuthConfig } from '../../services/api.service';
import yup from '../../services/yup.service';
import Text from '../../components/Text';
import { maskCpfOrCnpj } from '../../services/masks.service';

type banksList = {
	ispb: string;
	name: string;
	code: number;
	fullName: string;
};

const OnrPreRegisterBankForm: React.FC = () => {
	const { addToast } = useToasts();
	const history = useHistory();
	const { currentSeller } = useCurrentSeller();
	const [isLoading, setIsLoading] = React.useState<boolean>(false);
	const [banks, setBanks] = React.useState<Array<banksList>>([]);
	const apiConfig = getApiAuthConfig();
	const sellerApi = new SellersApi(apiConfig);

	const onrPreRegisterBankFormInitialValues = {
		bank_code: '',
		agency_number: '',
		agency_digit: '',
		account_number: '',
		account_digit: '',
		type_account: '',
		pix_key: '',
	};

	const onrPreRegisterBankFormSchema = yup.object().shape({
		bank_code: yup.string().required('Campo obrigatório'),
		agency_number: yup.string().required('Campo obrigatório').typeError('O valor deve ser do tipo numérico'),
		agency_digit: yup.string().nullable().typeError('O valor deve ser do tipo numérico').max(1, 'Permitido apenas um dígito'),
		account_number: yup.string().required('Campo obrigatório').typeError('O valor deve ser do tipo numérico'),
		account_digit: yup.string().nullable().typeError('O valor deve ser do tipo numérico').max(1, 'Permitido apenas um dígito'),
		type_account: yup.string().required('Campo obrigatório'),
		pix_key: yup.string().nullable(),
	});

	const completeBankCode = (value) => {
		return value.toString().padStart(3, '0');
	};

	const handleFormSubmit = async (values) => {
		if (!currentSeller?.id) {
			addToast('É necessário possuir um estabelecimento para continuar', {
				autoDismiss: true,
				appearance: 'error',
			});
			return;
		}
		setIsLoading(true);

		try {
			const preRegisterSellerAddBankInfoDto: PreRegisterSellerAddBankInfoDto = {
				account_number: values.account_number,
				agency_number: values.agency_number,
				bank_code: completeBankCode(values.bank_code),
				type_account: values.type_account,
				account_digit: values.account_digit,
				agency_digit: values.agency_digit,
			};

			if (values.pix_key) {
				preRegisterSellerAddBankInfoDto.pix_key = values.pix_key;
			}
			await sellerApi.preRegisterSellerAddBankInfo({ preRegisterSellerAddBankInfoDto, sellerId: currentSeller.id });

			history.push('/admin/dashboard');
		} catch (error) {
			addToast('Erro ao atualizar dados bancários, entre em contato com o suporte', {
				autoDismiss: true,
				appearance: 'error',
			});
		} finally {
			setIsLoading(false);
		}
	};

	React.useEffect(() => {
		const getBanks = async () => {
			const data = await fetch('https://brasilapi.com.br/api/banks/v1');

			const jsonBanks = await data.json();

			setBanks(jsonBanks);
		};

		getBanks().catch(() => {
			addToast('Erro ao carregar os bancos', {
				autoDismiss: true,
				appearance: 'error',
			});
		});
	}, [addToast]);

	return (
		<Flex flexDir='column' p={10} bgColor='white' rounded='md' boxShadow='sm' mt='2' w='100%'>
			<Title id='pre-register-bank-account-title' textDecoration='underline' mb='30px'>
				Dados Bancários
			</Title>

			<Flex mb='25px' flexDirection='column'>
				<Text id='pre-register-bank-account-data-information-text'>Você deve informar os dados bancários da mesma titularidade do cadastro.</Text>
				<Text id='pre-register-bank-account-seller-document-text'>
					Documento do cartório: <b>{maskCpfOrCnpj(currentSeller?.document)}</b>.
				</Text>
			</Flex>
			<Formik
				validateOnMount
				initialValues={onrPreRegisterBankFormInitialValues}
				onSubmit={handleFormSubmit}
				validationSchema={onrPreRegisterBankFormSchema}
			>
				{({ handleSubmit, values, isValid }) => (
					<form onSubmit={handleSubmit}>
						<Flex w='100%'>
							<SimpleGrid columns={{ base: 1, md: 6, xl: 12 }} gap={1}>
								<GridItem colSpan={{ base: 12, xl: 6 }}>
									<FormControl id='pre-register-bank-code' isRequired>
										<FormLabel id='pre-register-bank-account-code-label' fontSize='md' fontWeight='bold'>
											Banco
										</FormLabel>
										<FormSelect id='owner-birth-day-select' name='bank_code'>
											<option value='' />
											{banks?.length &&
												banks.map((bank) => (
													<option value={bank.code} key={bank.code}>
														{bank.code} - {bank.fullName}
													</option>
												))}
										</FormSelect>
										<FormErrorMessage id='pre-register-bank-account-code-form-error-text' name='bank_code' />
									</FormControl>
								</GridItem>
								<GridItem colSpan={{ base: 12, xl: 6 }}>
									<FormControl id='pre-register-bank-account-type-form-control' isRequired>
										<FormLabel id='pre-register-bank-account-type-form-label' fontSize='md' fontWeight='bold'>
											Tipo de conta
										</FormLabel>
										<FormSelect id='pre-register-bank-account-type-form-select' name='type_account'>
											<option value='' />
											{type_account.map((type) => (
												<option value={type.value} key={type.value}>
													{type.text}
												</option>
											))}
										</FormSelect>
										<FormErrorMessage id='pre-register-bank-account-type-form-error-text' name='type_account' />
									</FormControl>
								</GridItem>
								<GridItem colSpan={{ base: 12, xl: 8 }}>
									<FormControl id='pre-register-bank-agency-number-form-control' isRequired>
										<FormLabel id='pre-register-bank-agency-number-form-label' fontSize='md' fontWeight='bold'>
											Número da agência
										</FormLabel>
										<FormInput id='pre-register-bank-agency-number-form-input' as={Input} name='agency_number' />
										<FormErrorMessage id='pre-register-bank-agency-number-form-error-text' name='agency_number' />
									</FormControl>
								</GridItem>
								<GridItem colSpan={{ base: 12, xl: 4 }}>
									<FormControl id='pre-register-bank-agency-digit-form-control'>
										<FormLabel id='pre-register-bank-agency-digit-form-label' fontSize='md' fontWeight='bold'>
											Dígito
										</FormLabel>
										<FormInput id='pre-register-bank-agency-digit-form-input' as={Input} name='agency_digit' />
										<FormErrorMessage id='pre-register-bank-agency-digit-form-error-text' name='agency_digit' />
									</FormControl>
								</GridItem>
								<GridItem colSpan={{ base: 12, xl: 8 }}>
									<FormControl id='pre-register-bank-account-number-form-input' isRequired>
										<FormLabel id='pre-register-bank-account-number-form-label' fontSize='md' fontWeight='bold'>
											Número da conta
										</FormLabel>
										<FormInput id='pre-register-bank-account-number-form-input' as={Input} name='account_number' />
										<FormErrorMessage id='pre-register-bank-account-number-form-error-text' name='account_number' />
									</FormControl>
								</GridItem>
								<GridItem colSpan={{ base: 12, xl: 4 }}>
									<FormControl id='pre-register-bank-account-digit-form-control'>
										<FormLabel id='pre-register-bank-account-digit-form-label' fontSize='md' fontWeight='bold'>
											Dígito
										</FormLabel>
										<FormInput id='pre-register-bank-account-digit-form-input' as={Input} name='account_digit' />
										<FormErrorMessage id='pre-register-bank-account-digit-form-error-text' name='account_digit' />
									</FormControl>
								</GridItem>
								<GridItem colSpan={{ base: 12 }}>
									<FormControl id='pre-register-bank-pix-key-form'>
										<FormLabel id='pre-register-bank-pix-key-label' fontSize='md' fontWeight='bold'>
											Chave Pix
										</FormLabel>
										<FormInput id='pre-register-bank-pix-key-form-input' as={Input} name='pix_key' />
										<FormErrorMessage id='pre-register-bank-pix-key-form-eror-text' name='pix_key' />
									</FormControl>
								</GridItem>
								<GridItem colSpan={{ base: 12 }} mt='10px'>
									<Button id='onr-pre-regiter-submit-button' type='submit' isLoading={isLoading} disabled={!isValid} w='100%'>
										Enviar
									</Button>
								</GridItem>
							</SimpleGrid>
						</Flex>
					</form>
				)}
			</Formik>
		</Flex>
	);
};

export default OnrPreRegisterBankForm;
