import { Box, Divider, Flex, Image, Stack } from '@chakra-ui/react';
import React from 'react';
import { FaWhatsapp } from 'react-icons/fa';
import { MdEmail, MdLocalPhone } from 'react-icons/md';

import Text from '../../../components/Text';
import { urlImages } from '../../../config/constants';
import { colors } from '../utils/constants';
import ColorBoxes from './ColorBoxes';
import FooterContact from './FooterContact';
import FooterOfficeInfo from './FooterOfficeInfo';

type FooterProps = {
	firstLandingPage: boolean;
};

const Footer: React.FC<FooterProps> = (props: FooterProps) => {
	const { firstLandingPage } = props;
	return (
		<>
			<Stack w='100%' mt={firstLandingPage ? ['40px', '110px'] : '40px'} flexDirection='column' px='45px' justifyContent='start'>
				<Flex mb='15px'>
					<Text
						w='auto'
						borderRadius='20px'
						backgroundColor={!firstLandingPage ? 'white' : 'none'}
						px={!firstLandingPage ? '15px' : 'none'}
						textAlign={['justify', 'start']}
						id='landing-page-footer-about-title-text'
						fontSize={['36px', '50px']}
						color={colors.primaryColor}
						fontWeight='bold'
					>
						Sobre a PEX - Parcela Express
					</Text>
				</Flex>
				<Text
					textAlign={['justify', 'start']}
					id='landing-page-footer-about-subtitle-text'
					fontSize={['20px', '26px']}
					color={firstLandingPage ? `${colors.primaryColor}` : 'white'}
					fontWeight='bold'
				>
					A PEX - Parcela Express é uma solução de pagamentos focada na transformação digital do setor notarial e registral, oferecendo
					facilidade, transparência e segurança para os cartórios.
				</Text>
				<Flex pt='40px'>
					<Flex flexDirection='column' w={['100%', '60%']}>
						<Text
							textAlign={['justify', 'start']}
							id='landing-page-footer-about-description-text'
							fontSize={['16px', '22px']}
							color={firstLandingPage ? `${colors.primaryColor}` : 'white'}
						>
							Parceira das principais centrais eletrônicas, associações notariais e registrais, atualmente está presente em mais de{' '}
							<strong>3.500 cartórios no Brasil e integrada a mais de 25 sistemas de gestão.</strong> Com{' '}
							<strong>certificação LGPD e PCI DSS</strong>, a empresa garante conformidade com as mais rigorosas normas de proteção de
							dados. Acesse nosso site e descubra como podemos transformar a gestão do seu cartório!
						</Text>
					</Flex>
					<Flex display={['none', 'flex']}>
						<Image src={urlImages.openDigitalAccountOnrBtgMap} maxH='320px' mb={4} />
					</Flex>
				</Flex>
			</Stack>
			<Stack w='100%' mt={['20px', '80px']} pt='50px' flexDirection='column' px={['0', '80px']} alignItems={['center', 'start']} justifyContent='start' backgroundColor='white'>
				<Image h='65px' maxW='160px' src={urlImages.openDigitalAccountOnrBtgParcelaExpress} />
				<Text pl={['0', '8px']} textAlign='start' id='landing-page-footer-cnpj-text' fontSize='16px' color={colors.primaryColor}>
					<strong>Serviços Digitais SA</strong> - 32.602.300/0001-37
				</Text>
			</Stack>
			<Flex px='40px' py='30px' backgroundColor='white'>
				<Divider borderColor='gray' />
			</Flex>
			<Stack w='100%' bg={firstLandingPage ? 'linear-gradient(to left, #1b2573, black)' : `${colors.primaryColor}`} alignItems='center'>
				<Flex
					w='full'
					bgColor='white'
					borderBottomRightRadius='50px'
					justifyContent='space-between'
					flexDirection='row'
					pb='20px'
					px={['10px', '70px']}
				>
					<FooterOfficeInfo
						id='landing-page-footer-mg-company'
						title='Escritório Minas Gerais:'
						address='Alameda Oscar Niemeyer, 400 - 9º andar, Vila da Serra, Nova Lima - MG.'
					/>
					<Box w='1px' bg={colors.primaryColor} />
					<FooterOfficeInfo
						id='landing-page-footer-sp-company'
						title='Escritório São Paulo:'
						address='Praça General Gentil Falcão, 108 - 5º andar, Itaim Bibi, São Paulo - SP.'
					/>
				</Flex>
				<Flex>
					<Text
						fontWeight='bold'
						id='landing-page-footer-contact-text'
						fontSize={['20px', '28px']}
						color='white'
						pt='40px'
						borderBottom='1px solid white'
					>
						FALE COM NOSSOS CONSULTORES
					</Text>
				</Flex>
				<Flex pt='20px' mb='10px' px='20px' w='full' justifyContent='center'>
					<Flex mr='10px'>
						<FooterContact icon={<MdLocalPhone size={30} color='white' />} text='(31) 2581-8788' id='landing-page-footer-phone-text' />
					</Flex>
					<FooterContact
						icon={<FaWhatsapp size={30} color='white' />}
						text='(31) 99950-3615'
						id='landing-page-footer-whatsapp-phone-text'
					/>
				</Flex>
				<FooterContact
					icon={<MdEmail size={30} color='white' />}
					text='comercialcartorio@parcelaexpress.com.br'
					id='landing-page-footer-email-text'
				/>
				<Flex alignItems='end' pt='40px' pb='60px'>
					<ColorBoxes fat />
				</Flex>
			</Stack>
		</>
	);
};

export default Footer;
