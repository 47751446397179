import { Flex, Icon } from '@chakra-ui/react';
import React from 'react';
import { ImFileText2 } from 'react-icons/im';
import { useToasts } from 'react-toast-notifications';

import FileUploadInput from '../../../containers/FileUploadInput';

import { CreateAttachmentDto, CreateAttachmentDtoExtensionEnum } from '../../../clients';
import Text from '../../../components/Text';

type OpenDigitalAccountAttachmentsPros = {
	attachment: any;
	setAttachment: React.Dispatch<React.SetStateAction<CreateAttachmentDto>>;
};

const OpenAccountAttachment: React.FC<OpenDigitalAccountAttachmentsPros> = ({ attachment, setAttachment }) => {
	const { addToast } = useToasts();
	const maxSize = 10000000;
	const allowedExtensions = ['pdf', 'doc', 'docx', 'png', 'jpg', 'jpeg'];

	const getBase64 = async (file) => {
		return new Promise((resolve) => {
			const reader = new FileReader();
			reader.readAsDataURL(file);
			reader.onload = () => {
				if (reader.result !== null) {
					resolve(reader.result);
				} else {
					resolve(null);
				}
			};
		});
	};

	const getExtension = (filename: string): string => {
		const re = /(?:\.([^.]+))?$/;

		const ext = re.exec(filename)?.[1];

		return ext || '';
	};

	const verifyFile = (extension: string, size: number): boolean => {
		if (size > maxSize) {
			addToast('Anexo excede limite máximo de 10MB', {
				appearance: 'error',
				autoDismiss: true,
			});
			return false;
		}

		if (!allowedExtensions.includes(extension)) {
			addToast('Extensão não aceita', {
				appearance: 'error',
				autoDismiss: true,
			});
			return false;
		}

		return true;
	};

	const uploadFile = async (file) => {
		const { name, size } = file;

		const processedFile = await getBase64(file);

		const extension = getExtension(name);

		const isValidFile = verifyFile(extension, size);

		if (isValidFile) {
			setAttachment({
				name,
				extension: extension as CreateAttachmentDtoExtensionEnum,
				size,
				data: processedFile as string,
			});
		}
	};

	const download = (url: string) => {
		window.open(url);
	};

	const removeAttachment = () => {
		setAttachment({
			name: '',
			extension: CreateAttachmentDtoExtensionEnum.Doc,
			size: 0,
			data: '',
		});
	};

	return (
		<Flex flexDirection='column'>
			<Flex>
				{!attachment.name ? (
					<FileUploadInput
						id='file-upload-input'
						label='Importar Anexo'
						inputProps={{
							multiple: false,
							onChange: (e) => {
								if (e.target.files?.length) {
									uploadFile(e.target.files[0]);
								}
							},
						}}
					/>
				) : (
					<Flex flexDirection='column'>
						<Icon
							maxH='50px'
							maxW='50px'
							h='100%'
							w='100%'
							as={ImFileText2}
							alignSelf='center'
							{...(attachment.url && {
								cursor: 'pointer',
								onClick: () => download(attachment.url),
							})}
						/>
						<Text id='open-digital-account-attachment-text'>{attachment.name || ''}</Text>
						<Text
							decoration='underline'
							cursor='pointer'
							id='open-digital-account-remove-attachment-text'
							color='gray.800'
							onClick={() => removeAttachment()}
						>
							Remover
						</Text>
					</Flex>
				)}
			</Flex>
		</Flex>
	);
};

export default OpenAccountAttachment;
