import { Button, Flex, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay } from '@chakra-ui/react';
import React from 'react';

import Text from '../../../components/Text';

type Props = {
	isOpen: boolean;
	onClose: () => void;
};

const DocumentInformationModal: React.FC<Props> = (props: Props) => {
	const { isOpen, onClose } = props;
	return (
		<Modal isOpen={isOpen} onClose={onClose} isCentered size='md' trapFocus={false}>
			<ModalOverlay />
			<ModalContent>
				<ModalHeader>
					<ModalCloseButton />
				</ModalHeader>
				<ModalBody>
					<Flex direction='column' p='3'>
						<Text id='document-information-modal-content-text' textAlign='justify' color='black'>
							O documento informado no cadastro será o mesmo utilizado para a abertura de conta.
						</Text>
					</Flex>
				</ModalBody>
				<ModalFooter>
					<Button onClick={onClose} bgColor='#505C7F' color='white'>
						Entendi
					</Button>
				</ModalFooter>
			</ModalContent>
		</Modal>
	);
};

export default DocumentInformationModal;
