import { Box, Container, Flex, useBreakpointValue } from '@chakra-ui/react';
import React from 'react';
import { useParams } from 'react-router-dom';

import FirstLandingPage from './components/FirstLandingPage';
import Footer from './components/Footer';
import SecondLandingPage from './components/SecondLandingPage';
import ThirdLandingPage from './components/ThirdLandingPage';

enum LandingPages {
	First = '1',
	Second = '2',
	Third = '3',
}

const OnrLandingPage: React.FC = () => {
	const maxWidth = useBreakpointValue({ base: '100%', xl: '90%', '2xl': '60vw' });

	const { id } = useParams();

	const landingPageComponents = {
		[LandingPages.First]: FirstLandingPage,
		[LandingPages.Second]: SecondLandingPage,
		[LandingPages.Third]: ThirdLandingPage,
	};

	const LandingPageComponent = landingPageComponents[id];

	React.useEffect(() => {
		const container = document.getElementById('root');
		if (container) {
			container.scrollTo({ top: 0 });
		}
	}, [id]);

	return (
		<Flex bg={id === LandingPages.First ? 'white' : '#1B2371'}>
			<Container maxW={maxWidth} centerContent px={['0', '1rem']}>
				<Box w='full' pt='40px' pb='0px' px={id === LandingPages.First ? ['0', '40px'] : '0'} textAlign='center'>
					<LandingPageComponent />
					<Footer firstLandingPage={id === LandingPages.First} />
				</Box>
			</Container>
		</Flex>
	);
};

export default OnrLandingPage;
