import * as React from 'react';

import { InputGroup, InputProps, InputRightElement, Spinner, useDisclosure } from '@chakra-ui/react';
import { Field } from 'formik';
import { useDebouncedCallback } from 'use-debounce';

import InputMask from 'react-input-mask';

import { SellersApi } from '../../../clients';
import { getApiAuthConfig } from '../../../services/api.service';
import Input from '../../../components/Input';
import DocumentInformationModal from './DocumentInformationModal';

type FormCpfCnpjSellerInputProps = InputProps & {
	onFilled?: (value: string) => void;
	onUnfilled?: (value: string) => void;
	beforeSearch?: () => void;
	afterSearch?: () => void;
	onSearchFailed?: () => void;
	onSearchResults?: (data) => void;
	id: string;
};

const FormCpfCnpjSellerInput: React.FC<FormCpfCnpjSellerInputProps> = (props: FormCpfCnpjSellerInputProps) => {
	const { id, onFilled, onUnfilled, onSearchFailed, onSearchResults, beforeSearch, afterSearch, onChange, ...parsedProps } = props;
	const [isFilled, setIsFilled] = React.useState(false);
	const [isLoading, setIsLoading] = React.useState(false);
	const { isOpen, onClose, onOpen } = useDisclosure();

	const handleSearchSellerFormSubmit = async (document) => {
		const apiConfig = getApiAuthConfig();
		const sellerApi = new SellersApi(apiConfig);
		const response = await sellerApi.verifyIfDocumentCanCreateSeller({ document });
		return response;
	};

	const debounced = useDebouncedCallback(async (value) => {
		const parsedCpfOrCnpj = value.replace(/\D+/gi, '');

		setIsLoading(true);

		try {
			const data = await handleSearchSellerFormSubmit(parsedCpfOrCnpj);

			onSearchResults && onSearchResults(data);
			if (data.can_create) {
				onOpen();
			}
		} catch (e) {
			onSearchFailed && onSearchFailed();
		} finally {
			setIsLoading(false);
		}
		afterSearch && afterSearch();
	}, 1000);

	React.useEffect(
		() => () => {
			debounced.flush();
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[debounced]
	);

	return (
		<>
			<DocumentInformationModal isOpen={isOpen} onClose={onClose} />
			<Field {...parsedProps}>
				{({ form, field }) => {
					return (
						<InputMask
							borderColor={`darkGrey`}
							mask={field.value && field.value.replace(/\D+/gi, '').toString().length > 11 ? '99.999.999/9999-99' : '999.999.999-999'}
							maskChar={null}
							value={field.value}
							onBlur={form.handleBlur}
							{...(parsedProps.placeholder && { placeholder: parsedProps.placeholder })}
							onChange={(e) => {
								onChange && onChange(e);
								form.setFieldValue(field.name, e.target.value);

								const parsedValue = e.target.value.replace(/\D+/gi, '');

								if (parsedValue.length === 14) {
									debounced.callback(e.target.value);
									if (!isFilled) {
										setIsFilled(true);

										onFilled && onFilled(parsedValue);
									}
								} else if (parsedValue.length === 11) {
									if (!isFilled) {
										setIsFilled(true);

										onFilled && onFilled(parsedValue);
										debounced.callback(e.target.value);
									}
								} else {
									setIsFilled(false);

									onUnfilled && onUnfilled(parsedValue);
								}
							}}
							{...parsedProps}
						>
							{(inputProps) => {
								return (
									<InputGroup rounded={`lg`}>
										<Input id={id} type='text' {...inputProps} />
										{isLoading && (
											<InputRightElement
												pb={2}
												children={<Spinner thickness='1px' speed='0.65s' emptyColor='gray.200' color='blue.500' size='sm' p={2} mb={2} />}
											/>
										)}
									</InputGroup>
								);
							}}
						</InputMask>
					);
				}}
			</Field>
		</>
	);
};

export default FormCpfCnpjSellerInput;
