import { Flex, GridItem, SimpleGrid } from '@chakra-ui/react';
import React from 'react';
import { CreateAttachmentDto } from '../../../clients';
import Button from '../../../components/Button';
import FormLabel from '../../../components/Form/FormLabel';
import Text from '../../../components/Text';
import OpenAccountAttachment from '../../OpenDigitalAccount/components/OpenAccountAttachment';

type Attachment = {
	name: string;
	extension: string;
	size: number;
	data: string;
};

type LoggedOpenDigitalAccountProps = {
	attachment: Attachment;
	setAttachment: React.Dispatch<React.SetStateAction<CreateAttachmentDto>>;
	isLoading: boolean;
	handleSubmitAttachment: () => Promise<void>;
	sellerDocument: string;
	onOpen: () => void;
};

const LoggedOpenDigitalAccount: React.FC<LoggedOpenDigitalAccountProps> = ({
	attachment,
	setAttachment,
	isLoading,
	handleSubmitAttachment,
	sellerDocument,
	onOpen,
}: LoggedOpenDigitalAccountProps) => {
	return (
		<Flex maxH='100%' direction='column' p={10} bgColor='white' rounded='md' boxShadow='sm' mt='2' w='100%'>
			<Flex direction='column' width='100%'>
				<SimpleGrid columns={12} color='gray.700' gap={2}>
					<GridItem colSpan={{ base: 12 }}>
						<Text id='logged-open-digital-legal-information-title' fontSize='lg' color='darkGrey' mt='2'>
							Informações Legais
						</Text>
					</GridItem>
					<GridItem colSpan={{ base: 12 }}>
						<Flex direction='column' my='5'>
							<FormLabel id='open-digital-account-legal-nature-label' color='darkGrey' fontSize='md' fontWeight='medium'>
								Anexar Termo de Posse
							</FormLabel>
							<OpenAccountAttachment attachment={attachment} setAttachment={setAttachment} />
						</Flex>
					</GridItem>
					<GridItem colSpan={{ base: 12 }}>
						<Button
							id='logged-open-digital-account-submit-button'
							disabled={isLoading || !attachment.name}
							onClick={() => {
								handleSubmitAttachment();
								if (sellerDocument.length === 11) {
									onOpen();
								}
							}}
						>
							Enviar
						</Button>
					</GridItem>
				</SimpleGrid>
			</Flex>
		</Flex>
	);
};

export default LoggedOpenDigitalAccount;
