import { Box } from '@chakra-ui/react';
import React from 'react';
import { BiCheck } from 'react-icons/bi';

import Text from '../../../components/Text';

type BenefitCardsProps = {
	id: string;
	text: string;
	width?: string[];
	center?: boolean;
};

const BenefitCards: React.FC<BenefitCardsProps> = (props: BenefitCardsProps) => {
	const { id, text, center, width } = props;
	return (
		<Box
			position='relative'
			border='1px solid white'
			borderRadius='22px'
			p='16px'
			fontWeight='bold'
			w={width || ['100%', '33%']}
			textAlign='center'
			mb={['25px', '0']}
			mx={center ? ['0', '20px'] : '0'}
		>
			<Box
				position='absolute'
				top='-18px'
				left='50%'
				transform='translateX(-50%)'
				bg='#F86738'
				w='36px'
				h='36px'
				borderRadius='full'
				border='2px solid white'
				display='flex'
				alignItems='center'
				justifyContent='center'
			>
				<BiCheck size={28} color='white' />
			</Box>
			<Text id={`first-landing-page-benefits-card-${id}`} color='white'>
				{text}
			</Text>
		</Box>
	);
};

export default BenefitCards;
