import { Flex, GridItem, Image, SimpleGrid, Tooltip, chakra } from '@chakra-ui/react';
import React from 'react';
import { FaInfoCircle } from 'react-icons/fa';

import Button from '../../../components/Button';
import FormCnsInput from '../../../components/Form/FormCnsInput';
import FormControl from '../../../components/Form/FormControl';
import FormErrorMessage from '../../../components/Form/FormErrorMessage';
import FormInput from '../../../components/Form/FormInput';
import FormLabel from '../../../components/Form/FormLabel';
import FormPhoneInput from '../../../components/Form/FormPhoneInput';
import FormSelect from '../../../components/Form/FormSelect';
import FormZipcodeInput from '../../../components/Form/FormZipcodeInput';
import Text from '../../../components/Text';
import Title from '../../../components/Title';
import { seller_assignment, states } from '../../../config/constants';
import * as variables from '../../../config/variables';
import NewModal from '../../../containers/NewModal';
import getzipcodeService from '../../../services/getzipcode.service';
import FormCpfCnpjSellerInput from './FormCpfCnpjSellerInput';
import { prefixUrl } from '../../../services/prefix-url.service';

type SellerDataFormTypes = {
	setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
	values: any;
};

const SellerDataForm: React.FC<SellerDataFormTypes> = ({ setFieldValue, values }) => {
	const [isOpen, setIsOpen] = React.useState<boolean>(false);
	const onSearchResults = (data) => {
		setFieldValue('address.street', data.logradouro);
		setFieldValue('address.district', data.bairro);
		setFieldValue('address.city', data.localidade);
		setFieldValue('address.state', data.uf);
	};

	const openRedirectModal = (response) => {
		if (!response.can_create) {
			setIsOpen(!isOpen);
		}
	};

	const redirectToLogin = () => {
		window.location.href = prefixUrl('sipeonr', '?is_digital_account=true')
	};

	return (
		<Flex direction='column' my='20px'>
			<Title id='onr-pre-register-serventia-title' textAlign='center' textDecoration='underline' mb='30px' color='blackAlpha.600'>
				Dados da Serventia
			</Title>
			<SimpleGrid columns={12} color='gray.700' gap={2}>
				<GridItem colSpan={{ base: 12, xl: 6 }}>
					<FormControl id='onr-pre-register-form-control-document' isRequired>
						<FormLabel color='blackAlpha.600' id='onr-pre-register-seller-document-label' fontSize='md' fontWeight='bold'>
							CNPJ/CPF
						</FormLabel>
						<FormCpfCnpjSellerInput id='onr-pre-register-seller-document-form-input' name='document' onSearchResults={(e) => openRedirectModal(e)} />
						<FormErrorMessage id='onr-pre-register-seller-document-form-error-text' name='document' />
					</FormControl>
				</GridItem>
				<GridItem colSpan={{ base: 12, xl: 6 }}>
					<FormControl id='onr-pre-register-form-control-name' isRequired width='100%'>
						<FormLabel color='blackAlpha.600' id='onr-pre-register-seller-name-label' fontSize='md' fontWeight='bold'>
							Razão Social
						</FormLabel>
						<FormInput id='onr-pre-register-seller-name-form-input' name='name' />
						<FormErrorMessage id='onr-pre-register-seller-name-form-error-text' name='name' />
					</FormControl>
				</GridItem>
				<GridItem colSpan={{ base: 12, xl: 6 }}>
					<FormControl id='onr-pre-register-form-control-cns' isRequired>
						<FormLabel color='blackAlpha.600' id='onr-pre-register-seller-cns-label' fontSize='md' fontWeight='bold'>
							CNS
						</FormLabel>
						<FormCnsInput id='onr-pre-register-seller-cns-form-input' name='cns' />
						<FormErrorMessage id='onr-pre-register-seller-cns-form-error-text' name='cns' />
					</FormControl>
				</GridItem>
				<GridItem colSpan={{ base: 12,  xl: 6  }}>
					<FormControl id='onr-pre-register-form-control-description' isRequired width='100%'>
						<FormLabel color='blackAlpha.600' id='onr-pre-register-seller-description-label' fontSize='md' fontWeight='bold'>
							Nome Fantasia
						</FormLabel>
						<FormInput id='onr-pre-register-seller-description-form-input' name='description' />
						<FormErrorMessage id='onr-pre-register-seller-description-form-error-text' name='description' />
					</FormControl>
				</GridItem>
				<GridItem colSpan={{ base: 12, xl: 4 }}>
					<FormControl id='onr-pre-register-form-control-zipcode' isRequired width='100%'>
						<FormLabel color='blackAlpha.600' id='onr-pre-register-address-zipcode-label' fontSize='md' fontWeight='bold'>
							CEP
						</FormLabel>
						<FormZipcodeInput
							id='onr-pre-register-address-zipcode-form-zipcode-input'
							name='address.zipcode'
							performSearch={getzipcodeService}
							onSearchResults={(data) => onSearchResults(data)}
						/>
						<FormErrorMessage id='onr-pre-register-address-zipcode-form-error-text' name='address.zipcode' />
					</FormControl>
				</GridItem>
				<GridItem colSpan={{ base: 12, xl: 6 }}>
					<FormControl id='onr-pre-register-form-control-city' isRequired width='100%'>
						<FormLabel color='blackAlpha.600' id='onr-pre-register-seller-city-label' fontSize='md' fontWeight='bold'>
							Cidade
						</FormLabel>
						<FormInput id='onr-pre-register-seller-city-form-input' name='address.city' />
						<FormErrorMessage id='onr-pre-register-seller-city-form-error-text' name='address.city' />
					</FormControl>
				</GridItem>
				<GridItem colSpan={{ base: 12, xl: 2 }}>
					<FormControl id='onr-pre-register-form-control-uf' isRequired width='100%'>
						<FormLabel color='blackAlpha.600' id='onr-pre-register-address-state-form-select' fontSize='md' fontWeight='bold'>
							UF
						</FormLabel>
						<FormSelect id='onr-pre-register-address-state-form-select' fontSize='md' name='address.state'>
							<option id='onr-pre-register-state-form-select-empty' value='' />
							{states.map((state) => (
								<option id={`onr-pre-register-state-form-select-${state.value}`} value={state.value} key={state.value}>
									{state.text}
								</option>
							))}
						</FormSelect>
						<FormErrorMessage id='onr-pre-register-address-state-form-error-text' name='payer.address.state' />
					</FormControl>
				</GridItem>
				<GridItem colSpan={{ base: 12, xl: 10 }}>
					<FormControl id='onr-pre-register-form-control-street' isRequired>
						<FormLabel color='blackAlpha.600' id='onr-pre-register-seller-street-label' fontSize='md' fontWeight='bold'>
							Rua
						</FormLabel>
						<FormInput id='onr-pre-register-seller-street-form-input' name='address.street' />
						<FormErrorMessage id='onr-pre-register-seller-street-form-error-text' name='address.street' />
					</FormControl>
				</GridItem>
				<GridItem colSpan={{ base: 12, xl: 2 }}>
					<FormControl id='onr-pre-register-form-control-number' isRequired>
						<FormLabel color='blackAlpha.600' id='onr-pre-register-seller-number-label' fontSize='md' fontWeight='bold'>
							Número
						</FormLabel>
						<FormInput id='onr-pre-register-seller-number-form-input' name='address.number' />
						<FormErrorMessage id='onr-pre-register-seller-number-form-error-text' name='address.number' />
					</FormControl>
				</GridItem>
				<GridItem colSpan={{ base: 12, xl: 6 }}>
					<FormControl id='onr-pre-register-form-control-district' isRequired>
						<FormLabel color='blackAlpha.600' id='onr-pre-register-seller-district-label' fontSize='md' fontWeight='bold'>
							Bairro
						</FormLabel>
						<FormInput id='onr-pre-register-seller-district-form-input' name='address.district' />
						<FormErrorMessage id='onr-pre-register-seller-district-form-error-text' name='address.district' />
					</FormControl>
				</GridItem>
				<GridItem colSpan={{ base: 12, xl: 6 }}>
					<FormControl id='onr-pre-register-form-control-complement'>
						<FormLabel color='blackAlpha.600' id='onr-pre-register-seller-complement-label' fontSize='md' fontWeight='bold'>
							Complemento
						</FormLabel>
						<FormInput id='onr-pre-register-seller-complement-form-input' name='address.complement' />
						<FormErrorMessage id='onr-pre-register-seller-complement-form-error-text' name='address.complement' />
					</FormControl>
				</GridItem>
				<GridItem colSpan={{ base: 12, xl: 6 }}>
					<FormControl id='onr-pre-register-secondary-phone' isRequired>
						<FormLabel color='blackAlpha.600' id='onr-pre-register-settings-secondary-phone-label' fontSize='md' fontWeight='bold'>
							Telefone
						</FormLabel>
						<FormPhoneInput id='onr-pre-register-secondary-phone-form-input' name='secondary_phone' />
						<FormErrorMessage id='onr-pre-register-seller-secondary-phone-form-error-text' name='secondary_phone' />
					</FormControl>
				</GridItem>
				<GridItem colSpan={{ base: 12, xl: 6 }}>
					<FormControl id='onr-pre-register-tertiary-phone'>
						<FormLabel color='blackAlpha.600' id='onr-pre-register-settings-tertiary-phone-label' fontSize='md' fontWeight='bold'>
							Telefone Secundário
						</FormLabel>
						<FormPhoneInput id='onr-pre-register-tertiary-phone-form-input' name='tertiary_phone' />
						<FormErrorMessage id='onr-pre-register-seller-tertiary-phone-form-error-text' name='tertiary_phone' />
					</FormControl>
				</GridItem>
				<GridItem colSpan={{ base: 12 }}>
					<FormControl id='onr-pre-register-secondary-email' isRequired>
						<FormLabel color='blackAlpha.600' id='onr-pre-register-seller-secondary-email-label' fontSize='md' fontWeight='bold'>
							E-mail
						</FormLabel>
						<FormInput id='onr-pre-register-seller-form-secondary-email-form-input' name='secondary_email' />
						<FormErrorMessage id='onr-pre-register-seller-secondary-email-form-error-text' name='secondary_email' />
					</FormControl>
				</GridItem>
				<GridItem colSpan={{ base: 12 }}>
					<FormControl id='onr-pre-register-tertiary-email'>
						<FormLabel color='blackAlpha.600' id='onr-pre-register-seller-tertiary-email-label' fontSize='md' fontWeight='bold'>
							E-mail Secundário
						</FormLabel>
						<FormInput id='onr-pre-register-seller-form-tertiary-email-form-input' name='tertiary_email' />
						<FormErrorMessage id='onr-pre-register-seller-tertiary-email-form-error-text' name='tertiary_email' />
					</FormControl>
				</GridItem>
				<GridItem colSpan={{ base: 12, xl: 6 }}>
					<FormControl id='onr-pre-register-erp' isRequired>
						<FormLabel color='blackAlpha.600' id='onr-pre-register-seller-erp-label' fontSize='md' fontWeight='bold' display='flex' flexDirection='row'>
							Sistema de gestão (ERP)
							<Tooltip label='Sistema utilizado para lançamento e gestão dos protocolos' placement='top' hasArrow>
								<chakra.span mx='1'>
									<FaInfoCircle />
								</chakra.span>
							</Tooltip>
						</FormLabel>
						<FormInput id='onr-pre-register-erp' name='erp' />
						<FormErrorMessage id='erp-form-error-text' name='erp' />
					</FormControl>
				</GridItem>
				<GridItem colSpan={{ base: 12, xl: 6 }}>
					<FormControl id='onr-pre-register-seller_assignment' isRequired>
						<FormLabel 
							color='blackAlpha.600'
							id='onr-pre-register-seller-seller_assignment-label'
							fontSize='md'
							fontWeight='bold'
							display='flex'
							flexDirection='row'
						>
							Atribuiçao/convênio
							<Tooltip label='Cartório de notas, Cartório de Protesto,Cartório Civil' placement='top' hasArrow>
								<chakra.span mx='1'>
									<FaInfoCircle />
								</chakra.span>
							</Tooltip>
						</FormLabel>
						<FormSelect id='onr-pre-register-seller_assignment' name='seller_assignment'>
							<option value='' />
							{seller_assignment.map((assignment) => (
								<option value={assignment.key} key={assignment.key}>
									{assignment.text}
								</option>
							))}
						</FormSelect>
						<FormErrorMessage id='onr-pre-register-seller-assignment-form-error-text' name='seller_assignment' />
					</FormControl>
				</GridItem>
			</SimpleGrid>
			<NewModal isOpen={isOpen} onClose={() => { }}>
				<Flex flexDir='column' alignItems='center' p='3'>
					<Image id='seller-logo-image' src={variables.logoUrl()} w={100} />
					<Text id='onr-pre-register-modal-text' my='3'>
						Este documento já está cadastrado na Parcela Express
					</Text>
					<Button bgColor='#4c5bd3' cursor='pointer' id='onr-pre-register-modal-button-text' onClick={() => redirectToLogin()}>
						Acessar
					</Button>
				</Flex>
			</NewModal>
		</Flex>
	);
};

export default SellerDataForm;
