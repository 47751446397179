import { Flex, Image, useBreakpointValue } from '@chakra-ui/react';
import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';

import { Formik } from 'formik';

import { PreRegisterSellerDto, PreRegisterSellerDtoAbsorbCostsEnum, SellersApi, SellerTypeEnum } from '../../clients';
import Button from '../../components/Button';
import Title from '../../components/Title';
import { urlImages } from '../../config/constants';
import { getApiAuthConfig } from '../../services/api.service';
import CustomizationForm from './components/CustomizationForm';
import OwnerDataForm from './components/OwnerDataForm';
import SellerDataForm from './components/SellerDataForm';
import preRegisterSellerInitialValues from './validations/initialValues';
import preRegisterSellerSchema from './validations/schema';

const OnrPreRegister: React.FC = () => {
	const location = useLocation();
	const query = new URLSearchParams(location.search);
	const is_pex_customer = query.get('is_pex_customer');
	const { addToast } = useToasts();
	const history = useHistory();
	const apiConfig = getApiAuthConfig();
	const sellersApi = new SellersApi(apiConfig);
	const [isLoading, setIsLoading] = React.useState<boolean>(false);
	const isMobile = useBreakpointValue({ base: true, lg: false });

	const handleFormSubmit = async (values, isValid) => {
		if (!isValid) {
			return;
		}
		const {
			active_debit,
			active_credit_1x,
			active_credit_2x_to_18x,
			active_billet,
			active_pix_pos,
			active_pix_online,
			pass_on_cost_debit,
			pass_on_cost_credit_1x,
			pass_on_cost_credit_2x_to_18x,
			...parsedData
		} = values;

		setIsLoading(true);

		const preRegisterSellerDto: PreRegisterSellerDto = {
			active_debit: false,
			active_credit_1x: false,
			active_credit_2x_to_18x: false,
			active_billet: false,
			active_pix_pos: false,
			active_pix_online: false,
			is_onr: true,
			type: SellerTypeEnum.Transaction,
			...parsedData,
		};

		if (active_debit === 'true') {
			preRegisterSellerDto.active_debit = true;
		}
		if (active_credit_1x === 'true') {
			preRegisterSellerDto.active_credit_1x = true;
		}
		if (active_credit_2x_to_18x === 'true') {
			preRegisterSellerDto.active_credit_2x_to_18x = true;
		}
		if (active_billet === 'true') {
			preRegisterSellerDto.active_billet = true;
		}
		if (active_pix_pos === 'true') {
			preRegisterSellerDto.active_pix_pos = true;
		}
		if (active_pix_online === 'true') {
			preRegisterSellerDto.active_pix_online = true;
		}

		preRegisterSellerDto.absorb_costs = [];

		if (pass_on_cost_debit === 'false') {
			preRegisterSellerDto.absorb_costs.push(PreRegisterSellerDtoAbsorbCostsEnum.Debit);
		}

		if (pass_on_cost_credit_1x === 'false') {
			preRegisterSellerDto.absorb_costs.push(PreRegisterSellerDtoAbsorbCostsEnum.Credit1x);
		}

		if (pass_on_cost_credit_2x_to_18x === 'false') {
			preRegisterSellerDto.absorb_costs.push(
				PreRegisterSellerDtoAbsorbCostsEnum.Credit2x,
				PreRegisterSellerDtoAbsorbCostsEnum.Credit3x,
				PreRegisterSellerDtoAbsorbCostsEnum.Credit4x,
				PreRegisterSellerDtoAbsorbCostsEnum.Credit5x,
				PreRegisterSellerDtoAbsorbCostsEnum.Credit6x,
				PreRegisterSellerDtoAbsorbCostsEnum.Credit7x,
				PreRegisterSellerDtoAbsorbCostsEnum.Credit8x,
				PreRegisterSellerDtoAbsorbCostsEnum.Credit9x,
				PreRegisterSellerDtoAbsorbCostsEnum.Credit10x,
				PreRegisterSellerDtoAbsorbCostsEnum.Credit11x,
				PreRegisterSellerDtoAbsorbCostsEnum.Credit12x,
				PreRegisterSellerDtoAbsorbCostsEnum.Credit13x,
				PreRegisterSellerDtoAbsorbCostsEnum.Credit14x,
				PreRegisterSellerDtoAbsorbCostsEnum.Credit15x,
				PreRegisterSellerDtoAbsorbCostsEnum.Credit16x,
				PreRegisterSellerDtoAbsorbCostsEnum.Credit17x,
				PreRegisterSellerDtoAbsorbCostsEnum.Credit18x
			);
		}

		try {
			await sellersApi.preRegisterSeller({ preRegisterSellerDto });
				history.push(`/onr/2?is_pex_customer=false&document=${values.document.replace(/\D+/gi, '')}`);
		} catch (error) {
			addToast('Erro ao realizar cadastro', {
				appearance: 'error',
				autoDismiss: true,
			});
		} finally {
			setIsLoading(false);
		}
	};

	return (
		<Flex direction={['column', 'row']} height='100%' justifyContent='space-between'>
			<Flex maxH='100%' overflow='scroll' direction='column' alignItems='center' padding='30px' width='100%'>
				<Flex direction='column' width='100%' alignItems='center'>
					<Title id='onr-pre-register-title-1' textAlign='center' fontSize='xl' color='blackAlpha.600'>
						TERMO DE CREDENCIAMENTO CARTÓRIOS
					</Title>
					<Title id='onr-pre-register-title-2' textAlign='center' fontSize='xl' color='blackAlpha.600'>
						PARCELA EXPRESS
					</Title>
				</Flex>
				<Flex>
					<Formik
						validateOnMount
						initialValues={preRegisterSellerInitialValues}
						onSubmit={handleFormSubmit}
						validationSchema={preRegisterSellerSchema}
					>
						{({ handleSubmit, setFieldValue, values, isValid }) => (
							<form onSubmit={handleSubmit}>
								<SellerDataForm setFieldValue={setFieldValue} values={values} />
								<OwnerDataForm setFieldValue={setFieldValue} values={values} />
								<CustomizationForm values={values} />
								<Flex>
									<Button bgColor='#4c5bd3' id='onr-pre-regiter-submit-button' type='submit' isLoading={isLoading} disabled={!isValid} w='100%'>
										Enviar
									</Button>
								</Flex>
							</form>
						)}
					</Formik>
				</Flex>
			</Flex>
			{!isMobile && (
				<Flex width='100%' justifyContent='flex-end'>
					<Image
						maxH='100%'
						src={
							is_pex_customer && is_pex_customer === 'true'
								? urlImages.openDigitalAccountPexCustomer
								: urlImages.openDigitalAccountNoPexCustomer
						}
					/>
				</Flex>
			)}
		</Flex>
	);
};

export default OnrPreRegister;
