import * as React from 'react';

import { Route, Redirect, useLocation } from 'react-router-dom';
import { RouteProps } from 'react-router';

import { useAuth } from '../contexts/AuthProvider';

const PublicRoute = (routeProps: RouteProps) => {
  const Component: any = routeProps.component;
  const rest = {...routeProps};
	const location = useLocation();
	const query = new URLSearchParams(location.search);
	const is_digital_account = query.get('is_digital_account');

	delete rest.component;
	
	const { signed } = useAuth();

  return (
    <Route {...rest} render={(props) => {
			if (!signed) {
				return <Component {...props} />
			}
			return <Redirect to={{ pathname: is_digital_account ? '/admin/digital-account?is_digital_account=true' : '/admin/dashboard', state: { from: props.location }}} />
		}} />
  );
};

export default PublicRoute;
