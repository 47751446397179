import { Box, Button as ChakraButton, Flex, Image, Stack } from '@chakra-ui/react';
import React from 'react';
import { FaWhatsapp } from 'react-icons/fa';
import { useLocation } from 'react-router-dom';

import Text from '../../../components/Text';
import { urlImages } from '../../../config/constants';
import { colors } from '../utils/constants';
import ColorBoxes from './ColorBoxes';
import LandingPageCheckbox from './LandingPageCheckbox';
import { prefixUrl } from '../../../services/prefix-url.service';

const ThirdLandingPage: React.FC = () => {
	const location = useLocation();
	const query = new URLSearchParams(location.search);
	const is_pex_customer = query.get('is_pex_customer') === 'true';

	const [checkboxes, setCheckboxes] = React.useState({
		pix_qrcode: false,
		instant_pix: false,
		terminal: false,
	});

	const allChecked = Object.values(checkboxes).every(Boolean);

	const handleCheckboxChange = (name: string) => {
		setCheckboxes((prev) => ({ ...prev, [name]: !prev[name] }));
	};

	const navigateToParam = async (url: string) => {
		window.location.href = url;
	};

	const navigateToWhatsApp = () => {
		window.open(
			'https://wa.me/5531999503615?text=Olá,%20preciso%20de%20ajuda%20com%20a%20abertura%20de%20conta%20BTG%20Pactual.',
			'_blank'
		);
	};

	return (
		<Flex flexDirection='column' pt={['0', '20px']} pb={['30px', '60px']}>
			<Flex justifyContent={['center', 'space-between']} alignItems='center' flexDirection={['column', 'row']}>
				<Flex flexDirection={['column', 'row']} alignItems='center'>
					<Image src={urlImages.openDigitalAccountOnrBtgLogoWhite} maxW='210px' mr={['0', '20px']} />
					<Image src={urlImages.openDigitalAccountOnrBtgParcelaExpressWhite} maxW='180px' />
				</Flex>
				<Flex alignItems='end' mt={['20px', '0']}>
					<ColorBoxes />
				</Flex>
			</Flex>
			<Flex flexDirection='row' w='100%' alignItems='start' justifyContent='right' mt='40px'>
				<Flex w='40%' display={['none', 'flex']}>
					<Image src={urlImages.openDigitalAccountOnrCellphoneAndCard} />
				</Flex>
				<Flex w={['100%', '60%']} flexDirection='column'>
					<Text
						fontWeight='semibold'
						textAlign={['center', 'end']}
						id='third-landing-page-background-title-text'
						fontSize={['34px', '48px']}
						color='white'
						px='8px'
					>
						Entendemos que você <br /> optou por{' '}
						<Box as='span' bg='#EF7E07' color='#1B2371'>
							não abrir sua <br /> conta no BTG Pactual <br /> neste momento
						</Box>
					</Text>
					<Text
						mt='20px'
						mr={['0', '10px']}
						borderRight={['none', '1px solid #EF7E07']}
						id='third-landing-page-subtitle-text'
						color='white'
						textAlign={['center', 'end']}
						fontSize={['26px', '34px']}
						lineHeight='1.25'
						pr={['0', '15px']}
					>
						Com isso, alguns benefícios <br /> exclusivos dessa parceria <br />{' '}
						<Box as='span' bg='#EF7E07' color='#1B2371'>
							não estarão disponíveis
						</Box>{' '}
						<br />
						para sua serventia.
					</Text>
				</Flex>
			</Flex>

			<Box mx='20px' mt={['40px', '110px']} position='relative' h={['auto', '650px']}>
				<Box
					position={['relative', 'absolute']}
					top='0'
					left='0'
					w='100%'
					h='100%'
					backgroundImage="url('https://parcela-sub-api-prod-uploads.s3.us-east-1.amazonaws.com/checkboxBackground.png')"
					backgroundSize='cover'
					backgroundPosition='center'
					backgroundRepeat='no-repeat'
					opacity='0.5'
				/>
				<Stack
					position='relative'
					px={['0', '45px']}
					justifyContent={['center', 'flex-end']}
					alignItems={['center', 'flex-start']}
					h='100%'
					color='white'
					pb='20px'
					spacing='10px'
					flexDirection='column'
				>
					<Flex alignItems={['end']} justifyContent={'center'} pt={['0', '40px']} pb={['0', '15px']}>
						<ColorBoxes fat />
					</Flex>
					<Flex>
						<Flex w={['100%', '50%']} flexDirection='column'>
							<Text
								textAlign={['center', 'start']}
								id='third-landing-page-background-title-text'
								fontSize={['30px', '36px']}
								mb='20px'
								color='white'
								px='8px'
							>
								Ao{' '}
								<Box as='span' bg='#EF7E07' color='#1B2371'>
									{' '}
									não abrir uma conta,
								</Box>{' '}
								estou de acordo que:
							</Text>{' '}
							<LandingPageCheckbox
								id='third-landing-page-pix-qrcode-checkbox'
								isChecked={checkboxes.pix_qrcode}
								onChange={() => handleCheckboxChange('pix_qrcode')}
								label='Terei tarifa no Pix QR Code, sendo 0,5% do valor da transação, com um piso de R$0,49 e um teto de R$1,10.
'
							/>
							<LandingPageCheckbox
								id='third-landing-page-instant-pix-checkbox'
								isChecked={checkboxes.instant_pix}
								onChange={() => handleCheckboxChange('instant_pix')}
								label='Não terei o Pix recebido de forma instantânea. Os recebíveis do Pix QR Code serão creditados na conta bancária de escolha
									da serventia em D+1, até às 12h.'
							/>
							<LandingPageCheckbox
								id='third-landing-page-terminal-checkbox'
								isChecked={checkboxes.terminal}
								onChange={() => handleCheckboxChange('terminal')}
								label='Terei um valor de locação mensal por terminal de R$89,90.'
							/>
						</Flex>
						<Flex display={['none', 'flex']} w='50%' justifyContent='center'>
							<Image src={urlImages.openDigitalAccountOnrBtgTerminal} minW='300px' />
						</Flex>
					</Flex>
				</Stack>
			</Box>
			<Text id='third-landing-page-next-steps-text' mt='10px' color='white' fontSize={['20px', '24px']} px={['15px', '0']}>
				<Box as='span' color='#EF7E07' textDecoration='underline'>
					{is_pex_customer ? 'Como você já é cliente PEX - Parcela Express,' : 'Clique no botão "Avançar"'}
				</Box>{' '}
				{is_pex_customer
					? 'os dados bancários anteriormente cadastrados em nossa base continuam os mesmos. Confira os próximos passos.'
					: 'e preencha os dados bancários que deseja receber os valores transacionados na máquina de cartão e link de pagamento.'}
			</Text>

			<Stack
				w='100%'
				mt={['10px', '90px']}
				px='80px'
				alignItems={['center', 'end']}
				justifyContent='space-between'
				flexDirection={['column', 'row']}
			>
				<ChakraButton
					id='third-landing-page-next-steps-button'
					_hover={{ bgColor: colors.gradientButtonColor }}
					bg={colors.gradientButtonColor}
					px='16px'
					py='8px'
					display='flex'
					w='300px'
					onClick={() => {
						navigateToParam(
							is_pex_customer
								? prefixUrl('sipeonr', '?next_steps=true&no_open_account=true')
								: prefixUrl('sipeonr', '?bank_account=true&sent_credentials=true')
						);
					}}
					isDisabled={!allChecked}
				>
					<Text
						id='third-landing-page-next-steps-button-text'
						color='white'
						fontSize='16px'
						fontWeight='bold'
						display='flex'
						textAlign='center'
					>
						{is_pex_customer ? (
							<>
								Clique e conheça <br /> os próximos passos
							</>
						) : (
							'Quero avançar'
						)}
					</Text>
				</ChakraButton>
				<ChakraButton
					id='third-landing-page-contact-button'
					onClick={() => navigateToWhatsApp()}
					_hover={{ bg: 'green', opacity: 0.8 }}
					bg='green'
					px='16px'
					py='8px'
					display='flex'
					w='300px'
				>
					<FaWhatsapp color='white' size={20} />
					<Text
						id='third-landing-page-contact-button'
						ml='15px'
						color='white'
						fontSize='16px'
						fontWeight='bold'
						display='flex'
						textAlign='center'
					>
						Estou com dúvidas. <br /> Entrar em contato com a PEX!
					</Text>
				</ChakraButton>
			</Stack>
		</Flex>
	);
};

export default ThirdLandingPage;
