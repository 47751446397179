import { Box, Flex, Heading, Image, Modal, ModalContent, ModalOverlay, Stack } from '@chakra-ui/react';
import React from 'react';
import { useHistory } from 'react-router-dom';

import Text from '../../../components/Text';
import { urlImages } from '../../../config/constants';
import { colors } from '../utils/constants';
import ColorBoxes from './ColorBoxes';
import ModalBenefitItems from './ModalBenefitItems';
import Button from '../../../components/Button';
import { prefixUrl } from '../../../services/prefix-url.service';

type Props = {
	isOpen: boolean;
	onClose: () => void;
	isPexCustomer: boolean;
	document: string | null;
};

const NoOpenAccountModal: React.FC<Props> = (props: Props) => {
	const { isOpen, onClose, isPexCustomer, document } = props;
	const history = useHistory();
	const navigate = () => {isPexCustomer ? history.push(`/open-digital-account?document=${document}`): window.location.href = prefixUrl('sipeonr', '?is_digital_account=true');
	};

	const benefits = [
		'Maquininha Android de última geração, totalmente gratuita em comodato',
		'Pix QR Code dinâmico sem custos',
		'Recebimento de Pix QR Code instantâneo',
		'Acompanhamento personalizado das entradas via Pix QR Code dinâmico',
		'Geração de Pix QR Code e boleto bancário diretamente pelo seu sistema',
		'Conta sem mensalidade',
		'E muito mais!',
	];

	return (
		<Modal isOpen={isOpen} onClose={onClose} isCentered size='xl' trapFocus={false}>
			<ModalOverlay />
			<ModalContent
				rounded='md'
				backgroundImage="url('https://parcela-sub-api-prod-uploads.s3.us-east-1.amazonaws.com/modalBackground.png')"
				backgroundSize='cover'
				backgroundPosition='center'
				backgroundRepeat='no-repeat'
				p='15px'
			>
				<Flex direction='column' p='3'>
					<Flex justifyContent='space-between'>
						<Flex alignItems='end'>
							<ColorBoxes />
						</Flex>
						<Image src={urlImages.openDigitalAccountOnrBtgParcelaExpressWhite} maxH='50px' />
					</Flex>
					<Heading id='payment-data-date-heading' color='white' fontSize='34px' mt='20px' fontWeight='bold' textAlign='start'>
						Tem certeza que não deseja <br />
						<Box as='span' bg={colors.gradientTextColor} color='white' px='8px'>
							abrir sua conta
						</Box>{' '}
						no momento?
					</Heading>

					<Flex direction='column' fontSize='20px' color='white' fontWeight='bold' mt='10px'>
						<Text id='payment-id-text' color='white' textAlign='start'>
							Ao não abrir sua conta, você estará deixando <br /> de aproveitar os benefícios exclusivos!
						</Text>
					</Flex>
					<Flex>
						<Flex w={['100%', '60%']} flexDirection='column' mt='20px'>
							{benefits.map((item, index) => (
								<ModalBenefitItems key={index} text={item} id={`landing-page-modal-benefits-${index}`} />
							))}
						</Flex>
						<Flex display={['none', 'flex']}>
							<Image src={urlImages.openDigitalAccountOnrBtgTerminal} />
						</Flex>
					</Flex>
					<Stack w='100%' mt='10px' alignItems='end' justifyContent={['center', 'space-between']} flexDirection={['column', 'row']}>
						<Button
							bgColor='red.500'
							w={['350px', '450px']}
							h='50px'
							id='landing-page-modal-open-account-button'
							mr={['0', '50px']}
							onClick={() => {
								history.push(!isPexCustomer ? `/onr/3?is_pex_customer=true` : `/onr/3?is_pex_customer=false`);
							}}
						>
							NÃO DESEJO ABRIR CONTA
						</Button>
						<Button
							bgColor='green.500'
							w={['350px', '450px']}
							h='50px'
							id='landing-page-modal-no-open-account-button'
							onClick={() => {
								navigate();
							}}
						>
							DESEJO ABRIR MINHA CONTA
						</Button>
					</Stack>
				</Flex>
			</ModalContent>
		</Modal>
	);
};

export default NoOpenAccountModal;
