import { Flex, Modal, ModalContent, ModalOverlay, ModalHeader, ModalBody, ModalCloseButton, ModalFooter } from '@chakra-ui/react';
import React from 'react';
import Text from '../../../components/Text';
import { useHistory } from 'react-router-dom';
import Button from '../../../components/Button';

type Props = {
	isOpen: boolean;
};

const OpenDigitalAccountModal: React.FC<Props> = (props: Props) => {
	const { isOpen } = props;
	const history = useHistory();
	return (
		<Modal isOpen={isOpen} onClose={() => history.push(`/admin`)} isCentered size='md' trapFocus={false}>
			<ModalOverlay />
			<ModalContent>
				<ModalHeader display='flex' justifyContent='space-between' alignItems='center'>
					<Text id='digital-account-modal-header-text'>Abertura de Conta</Text>
					<ModalCloseButton />
				</ModalHeader>
				<ModalBody>
					<Flex direction='column' p='3'>
						<Text id='digital-account-modal-content-text' textAlign='justify'>
							Recebemos seu documento e ele será avaliado, em breve você receberá um email do BTG Pactual para prosseguir com a abertura da
							conta.
							<br />
							<br /> Confira em sua caixa de entrada e no spam.
						</Text>
					</Flex>
				</ModalBody>
				<ModalFooter>
					<Button
						id='digital-account-modal-next-steps-button'
						onClick={() => history.push(`/digital-account/finished?is_pex_customer=true`)}
					>
						Próximos passos
					</Button>
				</ModalFooter>
			</ModalContent>
		</Modal>
	);
};

export default OpenDigitalAccountModal;
