import React from 'react';

import { Flex, Box, Stack, Image, FormControl, useDisclosure } from '@chakra-ui/react';

import { useHistory, useLocation } from 'react-router-dom';
import { Formik } from 'formik';
import { useToasts } from 'react-toast-notifications';

import useModal from 'react-hooks-use-modal';

import * as yup from 'yup';

import FormInput from '../../components/Form/FormInput';
import FormErrorMessage from '../../components/Form/FormErrorMessage';
import FormPasswordInput from '../../components/Form/FormPasswordInput';
import Button from '../../components/Button';

import { useAuth } from '../../contexts/AuthProvider';
import { useTheme } from '../../contexts/ThemeProvider';
import { PermissionActionsEnum, PermissionSubjectEnum } from '../../clients';

import ForgotPasswordModal from './ForgotPasswordModal';
import { AppAbility, buildAbilityFor } from '../../config/ability';
import Text from '../../components/Text';
import NoOpenDigitalAccountModal from './NoOpenDigitalAccountModal';
import { logoUrl } from '../../config/variables';

export function getRedirectRoute(permissions: AppAbility) {
	const readAction = PermissionActionsEnum.Read;
	const routes = [
		{ url: '/admin/dashboard', subject: PermissionSubjectEnum.Dashboard },
		{ url: '/admin/venda/pos', subject: PermissionSubjectEnum.Pos },
		{ url: '/admin/venda/link', subject: PermissionSubjectEnum.Link },
	];

	const route = routes.find((route) => permissions.can(readAction, route.subject));

	return route ? route.url : null;
}

const Login: React.FC = () => {
	const [isLoading, setIsLoading] = React.useState<boolean>(false);
	const { isOpen, onOpen, onClose } = useDisclosure();
	const history = useHistory();
	const location = useLocation();
	const query = new URLSearchParams(location.search);
	const is_digital_account = query.get('is_digital_account');
	const is_bank_account = query.get('bank_account') === 'true';
	const no_open_account = query.get('no_open_account') === 'true';
	const sent_credentials = query.get('sent_credentials') === 'true';
	const next_steps = query.get('next_steps') === 'true';

	const { addToast } = useToasts();
	const { login } = useAuth();
	const { updateTheme } = useTheme();

	const [Modal, open, close] = useModal('root', {
		preventScroll: true,
	});

	const initialValues = {
		email: '',
		senha: '',
	};

	const schema = yup.object().shape({
		email: yup.string().email('Formato de e-mail inválido').required('Campo obrigatório'),
		password: yup.string().required('Campo obrigatório'),
	});

	const handleFormSubmit = async (values) => {
		setIsLoading(true);

		try {
			const loginData = await login({
				email: values.email,
				password: values.password,
			});

			updateTheme(loginData.theme);

			const permissions = buildAbilityFor(loginData);

			const redirectRoute = getRedirectRoute(permissions);

			if (next_steps) {
				history.push(`/digital-account/finished?is_pex_customer=true`);
				return;
			}
			if (is_digital_account) {
				history.push(`/admin/digital-account?is_digital_account=true`);
				return;
			}
			if (is_bank_account) {
				history.push(`/admin/bank-account`);
				return;
			} else {
				history.push(redirectRoute);
			}
		} catch (e) {
			addToast('E-mail ou senha inválidos', {
				appearance: 'error',
				autoDismiss: true,
			});
		} finally {
			setIsLoading(false);
		}
	};

	React.useEffect(() => {
		if (no_open_account || sent_credentials) {
			onOpen();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<Flex direction={['column', 'column', 'row']} height='100%'>
			<Modal>
				<ForgotPasswordModal
					afterSubmit={() => {
						close();

						addToast('E-mail enviado com sucesso', {
							appearance: 'success',
							autoDismiss: true,
						});
					}}
				/>
			</Modal>

			<Flex
				w={['100%', '100%', '40%']}
				h={['40%', '40%', 'auto']}
				justifyContent='center'
				alignItems='center'
				bgColor={['transparent', 'transparent', 'primary']}
			>
				<Image src={logoUrl()} w={[150, 150, 210]} />
			</Flex>

			<Flex w={['100%', '100%', '60%']} alignItems='center' justifyContent='center'>
				<Box
					w={['100%', '100%', '80%']}
					px='8'
					alignItems='center'
					flexDirection='row'
					justifyContent='center'
					overflow='visible'
					rounded='lg'
				>
					<Formik enableReinitialize initialValues={initialValues} validationSchema={schema} onSubmit={handleFormSubmit}>
						{({ handleSubmit, isValid }) => {
							return (
								<form onSubmit={handleSubmit}>
									<Stack spacing={3}>
										<Stack spacing={5}>
											<FormControl>
												<FormInput id='login-email-form-input' name='email' placeholder='Digite seu e-mail aqui' mb='1' />
												<FormErrorMessage id='login-email-form-error-text' name='email' />
											</FormControl>

											<FormControl>
												<FormPasswordInput
													id='login-password-form-input'
													name='password'
													placeholder='Digite sua senha aqui'
													mb='1'
													autoComplete='nope'
												/>
												<FormErrorMessage id='login-password-form-error-text' name='password' />
											</FormControl>
										</Stack>
										<Flex mt={4}>
											<Text
												id='forgot-password-text'
												fontSize='md'
												color='secondary'
												_hover={{ textDecoration: 'underline', cursor: 'pointer' }}
												onClick={open}
											>
												Esqueci minha senha
											</Text>
										</Flex>
										<Flex justifyContent={['center', 'center', 'flex-end']} mt={4}>
											<Button
												id='submit-login-button'
												w={['100%', '100%', 'auto']}
												type='submit'
												disabled={!isValid}
												isLoading={isLoading}
												bgColor='primary'
												className='button__login'
											>
												Entrar
											</Button>
										</Flex>
									</Stack>
								</form>
							);
						}}
					</Formik>
				</Box>
			</Flex>
			<NoOpenDigitalAccountModal isOpen={isOpen} onClose={onClose} sentCredentials={sent_credentials} noOpenAccount={no_open_account} />
		</Flex>
	);
};

export default Login;
