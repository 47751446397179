import { Flex, Modal, ModalContent, ModalOverlay, ModalHeader, ModalBody, ModalCloseButton, ModalFooter, Button } from '@chakra-ui/react';
import React from 'react';
import Text from '../../components/Text';

type Props = {
	isOpen: boolean;
	onClose: () => void;
	noOpenAccount: boolean;
	sentCredentials: boolean;
};

const NoOpenDigitalAccountModal: React.FC<Props> = (props: Props) => {
	const { isOpen, onClose, noOpenAccount, sentCredentials } = props;
	return (
		<Modal isOpen={isOpen} onClose={onClose} isCentered size='md' trapFocus={false}>
			<ModalOverlay />
			<ModalContent>
				<ModalHeader>
					<ModalCloseButton />
				</ModalHeader>
				<ModalBody>
					<Flex direction='column' p='3'>
						<Text id='no-open-digital-account-modal-content-text' textAlign='justify' color='black'>
							{noOpenAccount && 'Para atualizarmos como parceiro ONR, favor efetuar o login para assinatura do contrato.'}
							{sentCredentials &&
								'As suas credenciais foram enviadas por email, favor realizar o login para informar os dados bancários e realizar a assinatura do contrato.'}
						</Text>
					</Flex>
				</ModalBody>
				<ModalFooter>
					<Button onClick={onClose} bgColor='#22365F' color='white'>
						Entendi
					</Button>
				</ModalFooter>
			</ModalContent>
		</Modal>
	);
};

export default NoOpenDigitalAccountModal;
