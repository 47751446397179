import { Flex, Modal, ModalContent, ModalOverlay, ModalHeader, ModalBody, ModalCloseButton, ModalFooter, Button } from '@chakra-ui/react';
import React from 'react';
import Text from '../../../components/Text';

type Props = {
	isOpen: boolean;
	onClose: () => void;
};

const OpenDigitalAccountModal: React.FC<Props> = (props: Props) => {
	const { isOpen, onClose } = props;
	return (
		<Modal isOpen={isOpen} onClose={onClose} isCentered size='md' trapFocus={false}>
			<ModalOverlay />
			<ModalContent>
				<ModalHeader display='flex' justifyContent='space-between' alignItems='center'>
					<Text id='open-digital-account-modal-header-text'>Abertura de Conta</Text>
					<ModalCloseButton />
				</ModalHeader>
				<ModalBody>
					<Flex direction='column' p='3'>
						<Text id='open-digital-account-modal-content-text' textAlign='justify'>
							Recebemos seu documento e ele será avaliado, em breve você receberá um email do BTG Pactual para prosseguir com a abertura da
							conta.
							<br />
							<br /> Confira em sua caixa de entrada e no spam.
						</Text>
					</Flex>
				</ModalBody>
				<ModalFooter>
					<Button colorScheme='red' onClick={onClose}>
						Fechar
					</Button>
				</ModalFooter>
			</ModalContent>
		</Modal>
	);
};

export default OpenDigitalAccountModal;
